import { HTTP_CONSTANTS } from "../../constants/http-constants";
import FormDetail from "../../models/data/form-detail";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";

function _getFormDetail(formAPIKey: string, hostName: string, callback: ResponseCallback<FormDetail>): void {
	HttpService.get<FormDetail>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/v1/${formAPIKey}/${hostName}/detail`,
		(_: FormDetail) => {
			callback.success(_);
		},
		(error: HttpError) => {
			callback.error(error.error);
		}
	);
}

function _submitForm(formAPIKey: string, data: any, callback: ResponseCallback<any>): void {
	HttpService.post(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/data/${formAPIKey}/post`,
		data,
		(_: any) => {
			callback.success(_);
		},
		(error: HttpError) => {
			callback.error(error.error);
		}
	);
}

function _dirtyForm(formAPIKey: string): void {
	HttpService.get(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/reporting/${formAPIKey}/dirty`,
		() => {},
		() => {}
	);
}

const IntegrationService = {
	GetFormDetail: _getFormDetail,
	SubmitForm: _submitForm,
	DirtyForm: _dirtyForm,
};

export default IntegrationService;
