import React, { useCallback, useEffect, useState } from "react";
import { FaBug, FaCheck, FaExclamation } from "react-icons/fa";
import CreateSiteAlert from "../../components/create-site-alert/create-site-alert";
import InvoiceModal from "../../components/invoice-modal/invoice-modal";
import LoadingOverlay from "../../components/loading-overlay/loading-overlay";
import PricingBoard from "../../components/pricing-board/pricing-board";
import SelectableDropdown from "../../components/selectable-dropdown/selectable-dropdown";
import { SubscriptionTierMapper } from "../../models/data/subscription-tier";
import UserSite from "../../models/data/user-site";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import PaymentService from "../../services/payment/payment-service";
import SiteService from "../../services/session/site-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import "./site-settings-page.scss";
export default function SiteSettingsPage() {
	// show form to update the subscription tiers

	const [currentSite, updateCurrentSite] = useState<UserSite>(SiteService.getCurrentSite());
	const [originalTier, updateTier] = useState(currentSite.subscriptionTier);
	const [currentSiteId, updateCurrentSiteId] = useState(null);

	useEffect(() => {
		if (currentSite === null || currentSite === undefined) return;
		updateCurrentSiteId(currentSite.id);
	}, [currentSite]);

	const confirmedUpdateSite = useCallback((site: UserSite) => {
		SiteService.updateSite(
			site,
			(returnedSite: UserSite) => {
				updateCurrentSite({ ...returnedSite });
				updateTier(returnedSite.subscriptionTier);
				if (window.confetti)
					window.confetti.addConfetti({
						particleCount: 100,
						spread: 70,
						origin: { y: 0.6 },
					});
				ToastService.OpenToast(
					"site_update_toast",
					"Subscription Updated",
					`${returnedSite.name} has been updated`,
					FaCheck,
					new ToastConfig({
						iconColour: "success",
					})
				);
			},
			() => {
				ToastService.OpenToast(
					"site_failed_update_toast",
					"Subscription Update Failed",
					`${site.name} failed to update, if this continues please contact support.`,
					FaExclamation,
					new ToastConfig({
						iconColour: "danger",
					})
				);
			}
		);
	}, []);

	const updateSite = useCallback(
		(site: UserSite) => {
			PaymentService.GetUpgradeDraftInvoice({ ...site }, currentSite.subscriptionTier, {
				success(_) {
					if (_ !== null) {
						ModalService.OpenModal(
							"upcoming_invoice_modal",
							<InvoiceModal
								modalId="upcoming_invoice_modal"
								invoice={_}
								includeConfirmation={true}
								callback={(_: boolean) => {
									if (_) {
										confirmedUpdateSite(site);
										ModalService.CloseModal("upcoming_invoice_modal");
									} else {
										ModalService.CloseModal("upcoming_invoice_modal");
										ToastService.OpenToast(
											"tier_not_upgraded",
											"Upgrade Cancelled.",
											"Site has not been upgraded",
											FaExclamation,
											new ToastConfig({
												autoHide: true,
												iconColour: "warning",
											})
										);
									}
								}}
							/>
						);
					} else {
						ToastService.OpenToast(
							"error_fetching_invoice",
							"Error Fetching Upgrade Invoice",
							"If this problem persists, please contact our support team.",
							FaExclamation,
							new ToastConfig({
								iconColour: "danger",
							})
						);
					}
				},
				error(err) {
					ToastService.OpenToast(
						"error_fetching_invoice",
						"Error Fetching Upgrade Invoice",
						"If this problem persists, please contact our support team.",
						FaExclamation,
						new ToastConfig({
							iconColour: "danger",
						})
					);
				},
			});
		},
		[currentSite, confirmedUpdateSite]
	);

	useEffect(() => {
		SiteService.getUserSites((_sites: UserSite[]) => {
			if (_sites === null) return;
			_sites.forEach((s, i) => {
				if (s.id === currentSiteId) {
					updateCurrentSite({ ...s });
				}
			});
		});
	}, [currentSiteId]);

	useEffect(() => {
		var siteChangeSub = SiteService.siteUpdatedEvent.subscribe((_: UserSite) => {
			LoadingOverlay.Show();
			if (_ == null) return;
			updateCurrentSite({ ..._ });
			updateTier(_.subscriptionTier);
			updateCurrentSiteId(_.id);
			LoadingOverlay.Hide();
		});

		return () => {
			SiteService.siteUpdatedEvent.unsubscribe(siteChangeSub);
		};
	}, [currentSiteId]);

	const handleStartSubClick = () => {
		LoadingOverlay.Show();
		PaymentService.GetCheckoutSessionForSite(currentSite, {
			success(_) {
				window.location.href = _;
			},
			error(err) {
				LoadingOverlay.Hide();
				ToastService.OpenToast(
					"start_sub_fail",
					"Failed to get checkout session",
					"If this continues please contact support",
					FaBug,
					new ToastConfig({
						iconColour: "danger",
						headerColour: "danger",
						borderColour: "danger",
					})
				);
			},
		});
	};

	const handleDeleteClick = () => {
		ModalService.OpenModal(
			"cancelation_alert",
			<CreateSiteAlert
				modalId="cancelation_alert"
				callback={(_: boolean) => {
					if (_ === true) {
						PaymentService.CancelSubscription(currentSite, {
							success(_) {
								if (_ === null) return; // site wasnt deleted
								updateCurrentSite({ ..._ });
								ToastService.OpenToast(
									"site_deleted_toast",
									"Subscription Cancelled",
									`${_.name} has been deleted`,
									FaCheck,
									new ToastConfig({
										iconColour: "success",
									})
								);
							},
							error(err) {
								ToastService.OpenToast(
									"site_deleted_failed_toast",
									"Subscription Cancel Failed",
									`Something went wrong cancelling your subscription, if this continues please contact support.`,
									FaExclamation,
									new ToastConfig({
										iconColour: "danger",
									})
								);
							},
						});
					}
				}}
			/>
		);
	};

	const handleLastInvoiceClick = () => {
		PaymentService.GetLatestInvoice(currentSite, {
			success(_) {
				if (_ !== null && _.invoice_pdf !== "") window.open(_.invoice_pdf, "_blank");
				else
					ToastService.OpenToast(
						"error_fetching_invoice",
						"Error Fetching Invoice",
						"If this problem persists, please contact our support team.",
						FaExclamation,
						new ToastConfig({
							iconColour: "danger",
						})
					);
			},
			error(err) {
				ToastService.OpenToast(
					"error_fetching_invoice",
					"Error Fetching Invoice",
					"If this problem persists, please contact our support team.",
					FaExclamation,
					new ToastConfig({
						iconColour: "danger",
					})
				);
			},
		});
	};

	const handleUpcomingInvoiceClick = () => {
		PaymentService.GetUpcomingInvoice(currentSite, {
			success(_) {
				if (_ !== null)
					ModalService.OpenModal(
						"upcoming_invoice_modal",
						<InvoiceModal modalId="upcoming_invoice_modal" invoice={_} includeConfirmation={false} callback={(_: boolean) => {}} />
					);
				else
					ToastService.OpenToast(
						"error_fetching_invoice",
						"Error Fetching Invoice",
						"If this problem persists, please contact our support team.",
						FaExclamation,
						new ToastConfig({
							iconColour: "danger",
						})
					);
			},
			error(err) {
				ToastService.OpenToast(
					"error_fetching_invoice",
					"Error Fetching Invoice",
					"If this problem persists, please contact our support team.",
					FaExclamation,
					new ToastConfig({
						iconColour: "danger",
					})
				);
			},
		});
	};

	const renderStartSiteSub = () => {
		return (
			<>
				<div className="card bg-white py-2 px-4">
					<h4 className="text-left d-block mb-2">Start Site Subscription</h4>
					<div className="d-flex align-items-center justify-content-end">
						<span className="d-block">
							<strong>{SubscriptionTierMapper.FromTier(currentSite.subscriptionTier)}</strong> tier subscription
						</span>
						<div className="flex-fill"></div>
					</div>
					<div className="d-flex align-items-center justify-content-end mt-2">
						<button className="btn btn-outline-danger me-2 w-content" onClick={() => handleDeleteClick()}>
							Delete
						</button>
						<button className="btn btn-primary w-content" onClick={() => handleStartSubClick()}>
							Activate
						</button>
					</div>
				</div>
			</>
		);
	};

	const renderActiveSiteOptions = () => {
		return (
			<>
				<div className="flex-fill-even" style={{ minWidth: "350px" }}>
					<div className="card bg-white p-3">
						<h4 className="text-left d-block mb-3">Change Subscription Level</h4>
						<div>
							<div className="mb-2">
								<SelectableDropdown
									stateId={currentSiteId}
									options={["FREE", "STANDARD", "PREMIUM", "PROFESSIONAL"]}
									selectedOption={SubscriptionTierMapper.FromTier(currentSite.subscriptionTier)}
									onChange={(val: string) => {
										updateCurrentSiteId(currentSite.id);
										updateCurrentSite({ ...currentSite, subscriptionTier: SubscriptionTierMapper.ToTier(val) });
									}}
								/>
							</div>
							<div className="d-flex align-items-center justify-content-end">
								<span className="d-block">
									<span>Current Tier: {SubscriptionTierMapper.FromTier(originalTier)}</span>
								</span>
								<div className="flex-fill"></div>
								<button
									disabled={originalTier === currentSite.subscriptionTier}
									className={`btn w-content ${originalTier === currentSite.subscriptionTier ? "btn-outline-light" : "btn-primary"}`}
									onClick={() => updateSite({ ...currentSite })}
								>
									See Details
								</button>
							</div>
							<div className="py-1">
								<PricingBoard showButtons={false} showTitle={false} activeTier={currentSite.subscriptionTier} />
							</div>
						</div>
					</div>
				</div>

				<div className="flex-fill-even" style={{ minWidth: "350px" }}>
					<div className="card bg-white p-3">
						<h4 className="text-left d-block mb-3">Invoicing</h4>
						<span className="d-block mb-2">Download a PDF version of your invoices.</span>

						<span className="d-block mb-2 text-muted">Please note that all future invoices are not final.</span>
						<div className="d-flex align-items-center justify-content-end">
							<button className="btn btn-outline-primary w-content me-2" onClick={() => handleLastInvoiceClick()}>
								Last Invoice
							</button>
							<button className="btn btn-primary w-content" onClick={() => handleUpcomingInvoiceClick()}>
								Upcoming Invoice
							</button>
						</div>
					</div>
				</div>

				<div className="flex-fill-even" style={{ minWidth: "350px" }}>
					<div className="card bg-white p-3">
						<h4 className="text-left d-block mb-3">Cancel Subscription</h4>
						<span className="d-block mb-2">Your subscription will not renew after the current period ends.</span>
						<div className="d-flex align-items-center justify-content-end">
							<button className="btn btn-danger w-content" onClick={() => handleDeleteClick()}>
								Cancel Subscription
							</button>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{currentSite !== null && currentSiteId !== null && originalTier !== null ? (
				<div className="d-flex justify-content-start gap-2 align-items-top flex-wrap">
					{currentSite.active ? renderActiveSiteOptions() : renderStartSiteSub()}
				</div>
			) : (
				<>
					<p className="d-none">
						cs:{JSON.stringify(currentSite)}, csi: {currentSiteId}, ot:{originalTier}
					</p>
				</>
			)}
		</>
	);
}
