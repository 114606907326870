import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import UserSite from "../../models/data/user-site";
import SiteService from "../../services/session/site-service";
import "./site-select-dropdown-component.scss";

export default function SiteSelectDropdownComponent() {
	const [availableSites, setAvailableSites] = useState<UserSite[]>([]);
	const [activeSite, setActiveSite] = useState(SiteService.getCurrentSite());

	// Component loaded
	useEffect(() => {
		SiteService.getUserSites((userSites: UserSite[]) => {
			setAvailableSites(userSites);
		});

		var currentSiteUpdatedSub = SiteService.siteUpdatedEvent.subscribe((_) => {
			setActiveSite(_);
		});

		var siteListUpdateSub = SiteService.siteListUpdateEvent.subscribe(() => {
			SiteService.getUserSites((userSites: UserSite[]) => setAvailableSites(userSites));
		});

		return () => {
			SiteService.siteUpdatedEvent.unsubscribe(currentSiteUpdatedSub);
			SiteService.siteListUpdateEvent.unsubscribe(siteListUpdateSub);
		};
	}, []);

	// Component updated
	useEffect(() => {
		if (availableSites === null || availableSites.length === 0) return; // nothing we can set
		if (activeSite === null && availableSites.length > 0) setActiveSite(availableSites[0]);
		SiteService.setCurrentSite(activeSite);
	}, [activeSite, availableSites]);

	return (
		<div className="site-select-dropdown dropdown sm-100 mt-md-0 w-100">
			<OverlayTrigger
				placement="top"
				overlay={
					availableSites === null || activeSite === null ? (
						<span></span>
					) : (
						<Tooltip id="button-tooltip">{availableSites.length === 0 ? "NO SITE" : activeSite.name}</Tooltip>
					)
				}
			>
				<button
					className={`${
						availableSites === null || availableSites.length === 0 ? "disabled" : null
					} btn bg-white btn-outline-light text-dark dropdown-toggle w-100 dropdown-selector d-flex align-items-center justify-content-between`}
					type={"button"}
					id={"siteDropdownButton"}
					data-bs-toggle={"dropdown"}
					aria-expanded={"false"}
				>
					<span className="me-2">
						{activeSite === null ? "LOADING..." : availableSites === null || availableSites.length === 0 ? "NO SITE" : activeSite.name}
					</span>
				</button>
			</OverlayTrigger>

			<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="siteDropdownButton">
				{availableSites !== null &&
					availableSites.map((site: UserSite) => {
						return (
							<li key={site.id}>
								<button className="dropdown-item" onClick={() => setActiveSite(site)}>
									{site.name}
								</button>
							</li>
						);
					})}
			</ul>
		</div>
	);
}
