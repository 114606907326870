import React from "react";
import ModalService from "../../services/ui/modal-service";
import "./create-site-alert.scss";
export default function CreateSiteAlert({ modalId, callback }: { modalId: string; callback: (_: boolean) => void }) {
	const closeModal = (continueWithDelete: boolean) => {
		callback(continueWithDelete);
		ModalService.CloseModal(modalId);
	};

	return (
		<div className="bg-white p-2 rounded mb-2">
			<h3 className="mb-3">Cancel Subscription</h3>
			<p>Are you sure you want to cancel your subscription?</p>
			<p>Continuing will delete your site and data. This change can not be undone.</p>

			<div>{/* Something about payment finalisation... */}</div>

			<div className="d-flex justify-content-end align-items-center">
				<button className="btn btn-outline-primary me-2" onClick={() => closeModal(false)}>
					Close
				</button>
				<button className="btn btn-danger" onClick={() => closeModal(true)}>
					Cancel Subscription
				</button>
			</div>
		</div>
	);
}
