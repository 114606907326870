import React, { useCallback, useEffect, useState } from "react";
import { IErrorFix, IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import "./statefull-label.scss";
export default function StatefullLabel({ stateId, stateChangeEvent }: { stateId: string; stateChangeEvent: EventEmitter<IErrorResp> }) {
	const [errorMessage, setErrorMessage] = useState("");

	const validate = useCallback(
		(errorResp: IErrorResp) => {
			setErrorMessage("");
			errorResp.fixes.forEach((fix: IErrorFix) => {
				if (fix.key === stateId) {
					setErrorMessage(fix.value);
				}
			});
		},
		[stateId]
	);

	useEffect(() => {
		const stateSubscription = stateChangeEvent.subscribe((_: IErrorResp) => {
			validate(_);
		});

		return () => {
			stateChangeEvent.unsubscribe(stateSubscription);
		};
	}, [stateId, stateChangeEvent, validate]);

	const errorElement = () => {
		return errorMessage ? <label className="form-label text-danger small">{errorMessage}</label> : null;
	};

	return <div>{errorElement()}</div>;
}
