import React, { useCallback, useEffect, useState } from "react";
import { IErrorFix, IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import "./statefull-input.scss";
export default function StatefullInput({
	stateId,
	label,
	placeholder = "",
	defaultValue,
	inputType,
	autocompleteType,
	onChangeCallback,
	onPressCallback,
	stateChangeEvent,
	resetEvent = new EventEmitter<void>(),
	dirtyEvent = new EventEmitter<void>(),
	required = false,
}: {
	stateId: string;
	label: string;
	placeholder?: string;
	defaultValue: string;
	inputType: string;
	autocompleteType: string;
	onChangeCallback: (_: string) => void;
	onPressCallback: (_: any) => void;
	stateChangeEvent: EventEmitter<IErrorResp>;
	resetEvent?: EventEmitter<void>;
	dirtyEvent?: EventEmitter<void>;
	required?: boolean;
}) {
	const [value, setValue] = useState(defaultValue || "");
	const [forceError, setForceError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isDirty, setIsDirty] = useState(false);

	const validate = useCallback(
		(errorResp: IErrorResp) => {
			setForceError(false);
			setIsDirty(true);
			setErrorMessage("");
			if (errorResp !== null && errorResp.fixes != null) {
				errorResp.fixes.forEach((fix: IErrorFix) => {
					if (fix.key === stateId) {
						setErrorMessage(fix.value);
					}

					if (fix.key === "global") setForceError(true);
				});
			}
		},
		[stateId]
	);

	useEffect(() => {
		onChangeCallback(value);
		// eslint-disable-next-line
	}, [value]);

	useEffect(() => {
		resetEvent.subscribe(() => {
			setValue(defaultValue);
		});
		if (stateChangeEvent !== null) {
			const stateSubscription = stateChangeEvent.subscribe((_: IErrorResp) => {
				validate(_);
			});

			return () => {
				stateChangeEvent.unsubscribe(stateSubscription);
			};
		}
	}, [stateId, stateChangeEvent, validate, defaultValue, resetEvent]);

	const validStateClass = () => {
		if (forceError) return "is-invalid";
		if (!isDirty) return "";
		if (isDirty && errorMessage === "") return "is-valid";
		return "is-invalid";
	};

	const errorElement = () => {
		return errorMessage ? <label className="form-label text-danger small">{errorMessage}</label> : null;
	};

	return (
		<div>
			{label == null ? null : (
				<label htmlFor={`${stateId}Input`} className="form-label mb-0">
					{label}
				</label>
			)}
			{inputType === 'textarea' ?
				(<textarea required={required}
					placeholder={placeholder}
					autoComplete={autocompleteType}
					id={`${stateId}Input`}
					rows={5}
					className={`form-control ${validStateClass()}`}
					value={value}
					onChange={(event) => {
						setValue(event.target.value);
					}}
					onClick={() => {
						dirtyEvent.emit();
					}} />)
				:
				(<input
					required={required}
					placeholder={placeholder}
					autoComplete={autocompleteType}
					id={`${stateId}Input`}
					type={inputType}
					className={`form-control ${validStateClass()}`}
					value={value}
					onChange={(event) => {
						setValue(event.target.value);
					}}
					onKeyDown={(e: any) => onPressCallback(e)}
					onClick={() => {
						dirtyEvent.emit();
					}}
				/>)}
			{errorElement()}
		</div>
	);
}
