export enum SubscriptionTier {
	FREE = -1,
	STANDARD = 0,
	PREMIUM = 1,
	PROFESSIONAL = 2,
	ENTERPRISE = 3,
	CUSTOM = 4,
}

export class SubscriptionTierMapper {
	public static ToTier(val: string): SubscriptionTier {
		switch (val) {
			case "FREE":
				return SubscriptionTier.FREE;
			case "STANDARD":
				return SubscriptionTier.STANDARD;
			case "PREMIUM":
				return SubscriptionTier.PREMIUM;
			case "PROFESSIONAL":
				return SubscriptionTier.PROFESSIONAL;
			case "ENTERPRISE":
				return SubscriptionTier.ENTERPRISE;
			default:
				return SubscriptionTier.PREMIUM;
		}
	}

	public static FromTier(tier: SubscriptionTier): string {
		switch (tier) {
			case SubscriptionTier.FREE:
				return "FREE";
			case SubscriptionTier.STANDARD:
				return "STANDARD";
			case SubscriptionTier.PREMIUM:
				return "PREMIUM";
			case SubscriptionTier.PROFESSIONAL:
				return "PROFESSIONAL";
			case SubscriptionTier.ENTERPRISE:
				return "ENTERPRISE";
			default:
				return "PREMIUM";
		}
	}
}
