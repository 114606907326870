import React from "react";
import "./loading-component.scss";

// TODO: Update to be larger, and centered, perhaps use props to customise?
export default function LoadingComponent() {
	return (
		<>
			<div className="spinner-border text-primary">
				<span className="visually-hidden">Loading...</span>
			</div>
		</>
	);
}
