import React, { useEffect, useState } from "react";
import { FaCog, FaRegUser, FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import UserSite from "../../models/data/user-site";
import SiteService from "../../services/session/site-service";
import "./dashboard-header-component.scss";

export default function DashboardHeaderComponent({ logoutCallback }: { logoutCallback: () => void }) {
	const hasSites = SiteService.getCurrentSite() != null;
	const [currentSite, setCurrentSite] = useState(SiteService.getCurrentSite());
	const navigate = useNavigate();

	useEffect(() => {
		var siteSub = SiteService.siteUpdatedEvent.subscribe((_: UserSite) => setCurrentSite(_));

		return () => {
			SiteService.siteUpdatedEvent.unsubscribe(siteSub);
		};
	}, []);

	return (
		<div className="bg-white">
			<div className="container-fluid px-2 py-3">
				<div className="header-inner-content d-flex align-items-center justify-content-between">
					<div>
						<h4 className="m-0">{hasSites ? currentSite.name : "Setup"}</h4>
					</div>
					<div className="dropdown">
						<button
							className="btn btn-link btn-icon dropdown-selector"
							type="button"
							id="AccountDropdownButton"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<FaRegUser className="h4 m-0" />
						</button>
						<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="AccountDropdownButton">
							<li>
								<button
									className="dropdown-item d-flex justify-content-between align-items-center"
									onClick={() => navigate("/dashboard/account")}
								>
									Account
									<FaCog className="h4 m-0" />
								</button>
								<button className="dropdown-item d-flex justify-content-between align-items-center" onClick={() => logoutCallback()}>
									Sign Out
									<FaSignOutAlt className="h4 m-0" />
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
