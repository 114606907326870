import LoginResponse from "../responses/LoginResponse";
import RegisterResponse from "../responses/RegisterResponse";

export default class UserSession {
	public token: string;
	public refreshToken: string;
	public issuedDate: Date;
	public expiryDate: Date;
	public name: string;
	public email: string;
	public accountType: number;
	public appSumoUser: boolean;

	public FromLoginResponse(_: LoginResponse): UserSession {
		this.token = _.token;
		this.refreshToken = _.refreshToken;
		this.issuedDate = _.issuedDate;
		this.expiryDate = _.expiryDate;
		this.name = _.name;
		this.email = _.email;
		this.accountType = _.accountType;
		this.appSumoUser = _.appSumoUser;
		return this;
	}

	public FromRegisterResponse(_: RegisterResponse): UserSession {
		this.token = _.token;
		this.refreshToken = _.refreshToken;
		this.issuedDate = _.issuedDate;
		this.expiryDate = _.expiryDate;
		this.name = _.name;
		this.email = _.email;
		this.accountType = _.accountType;
		this.appSumoUser = _.appSumoUser;
		return this;
	}
}
