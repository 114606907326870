import React from "react";
import { FaTrash } from "react-icons/fa";
import RelativeTime from "../../misc/helpers/relative-time";
import FormDataRow from "../../models/data/form-data-row";
import { SiteDataColumn } from "../../models/data/site-data-column";
import "./data-row-component.scss";
export default function DataRowComponent({
	index,
	columns,
	rowData,
	deleteRowCallback,
}: {
	index: number;
	columns: SiteDataColumn[];
	rowData: FormDataRow;
	deleteRowCallback: () => void;
}) {
	const renderSwitch = (data: any, dataType: "text" | "number" | "date" | "dropdown" | "textarea") => {
		switch (dataType) {
			case "text":
				return data;
			case "number":
				return data;
			case "dropdown":
				return data;
			case "textarea":
				return data;
			case "date":
				return new Date(data).toLocaleString("en-GB");

			default: return data
		}
	};

	const rowDataCell = (id: number, data: string, column: SiteDataColumn) => {
		return (
			<div key={id} className={`d-flex align-items-start justify-content-between w-100 ${id % 2 === 0 ? "bg-light" : "bg-white"} p-2 rounded`}>
				<span className="text-capitalize me-4">{column.name}</span>
				<strong>{data === undefined || data === "" ? <i>Not Provided</i> : renderSwitch(data, column.type)}</strong>
			</div>
		);
	};

	const unmappedColumns = () => {
		var resultKeys = Object.keys(rowData.data);
		var unmappedDataKeys = resultKeys.filter((k) => {
			var _ = columns.filter((c) => c.name.toLowerCase() === k.toLowerCase());
			return _.length === 0;
		});

		return unmappedDataKeys.map((udk: string, id: number) => {
			return rowDataCell(id, rowData.data[udk], new SiteDataColumn(udk, false, "text"));
		});
	};

	const hasUnmappedData = () => {
		var resultKeys = Object.keys(rowData.data);
		var unmappedDataKeys = resultKeys.filter((k) => {
			var _ = columns.filter((c) => c.name.toLowerCase() === k.toLowerCase());
			return _.length === 0;
		});
		return unmappedDataKeys.length > 0;
	};

	return (
		<div className="p-2 bg-white rounded my-3 pe-auto border">
			{/* header */}
			<div className="d-flex align-items-center justify-content-between py-0 px-2">
				<div className="REMOVEME-form-check mb-0">
					{/* <input className="form-check-input" type="checkbox" value="" id={`data_row_${index}`} /> */}
					<label className={`form-check-label text-muted small`} htmlFor={`data_row_${index}`}>
						{RelativeTime(rowData.submissionDate)}
					</label>
				</div>
				<div></div>
				<div>
					<FaTrash className="h6 mb-0 text-danger interact" onClick={deleteRowCallback} />
				</div>
			</div>
			<div className="border-bottom my-2"></div>

			{/* row content */}
			<div className="d-flex flex-wrap">
				{columns.map((c: SiteDataColumn, i: number) => {
					return rowDataCell(i, rowData.data[c.name.toLowerCase()], c);
				})}
			</div>

			{/* unmapped columns */}

			{hasUnmappedData() ? (
				<>
					<div className="border-bottom my-2"></div>

					<div className="accordion" id={`accordionUnmapped_${index}`}>
						<div className="accordion-item">
							<h2 className="accordion-header" id={`headingUnmapped_${index}`}>
								<button
									className="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target={`#collapseUnmapped_${index}`}
									aria-expanded="true"
									aria-controls={`$collapseUnmapped_${index}`}
								>
									Show unmapped data
								</button>
							</h2>
							<div
								id={`collapseUnmapped_${index}`}
								className="accordion-collapse collapse"
								aria-labelledby={`headingUnmapped_${index}`}
								data-bs-parent={`#accordionUnmapped_${index}`}
							>
								<div>{unmappedColumns()}</div>
							</div>
						</div>
					</div>
				</>
			) : null}

			{/* action */}
			{/* <div className="border-bottom my-2"></div>
			<div className="d-flex align-items-center justify-content-between py-1 px-2">
				<span className="text-muted small">Sender IP: {renderSwitch(rowData.senderIp, "text")}</span>
			</div> */}
		</div>
	);
}
