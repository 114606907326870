import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../services/session/user-service";
import "./recovery-page.scss";
import logo from "../../assets/images/mdp_logo_black.png";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import StatefullInput from "../../components/statefull-input/statefull-input";
import StatefullLabel from "../../components/statefull-label/statefull-label";
import heroImage from "../../assets/images/HeroImage_1.png";
import ToastService from "../../services/ui/toast-service";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { ToastConfig } from "../../models/interfaces/IMDPToast";

export default function RecoveryPage() {
	const [email, updateEmail] = useState("");
	const [forgotRequest, updateForgotRequest] = useState(null);

	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());
	const navigate = useNavigate();

	const keyDownHandler = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			MakeForgotRequest();
		}
	};

	useEffect(() => {
		updateForgotRequest({ email: email });
	}, [email]);

	const MakeForgotRequest = () => {
		UserService.forgotPassword(forgotRequest, {
			success(_) {
				ToastService.OpenToast(
					"forgot_request",
					"Success",
					"If this is a valid account we will email you with instructions on how to reset your password",
					FaCheck,
					new ToastConfig().success()
				);
				navigate("/auth/login");
			},
			error(err) {
				ToastService.OpenToast(
					"forgot_request_fail",
					"Something went wrong",
					"We couldn't process your request, please try again.",
					FaExclamation,
					new ToastConfig().danger()
				);
			},
		});
	};

	return (
		<div className="pg-100 bg-light d-flex align-items-stretch">
			<div className="bg-light col-12 col-lg-6 d-flex align-items-center justify-content-center">
				<div className="w-75 m-auto">
					<div>
						<Link to={"/"}>
							<img src={logo} height="50" className="d-inline-block align-top mb-5" alt="mdp logo" />
						</Link>
						<p className="h3">Forgot Password</p>
						<p className="lead">
							If an account is linked to this account an email will be sent with further instructions to get you back on track!
						</p>
					</div>

					<form className="mt-4">
						<div className="mb-3">
							<StatefullInput
								stateId="email"
								label="E-mail"
								defaultValue={email}
								inputType="text"
								autocompleteType="email"
								onChangeCallback={(val: string) => updateEmail(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>
					</form>

					<StatefullLabel stateId="global" stateChangeEvent={errorState} />

					<div className="border-bottom my-4"></div>

					<div className="d-flex justify-content-between align-items-center">
						<div></div>

						<div className="">
							<button className="btn btn-success" onClick={MakeForgotRequest}>
								Submit
							</button>
						</div>
					</div>

					<div className="text-center mt-4">
						<span className="me-2">Got your details?</span>
						<Link to="/auth/login">
							<span className="text-success">
								<strong>Log in</strong>
							</span>
						</Link>
					</div>
				</div>
			</div>

			<div className="bg-success col-6 lg-6 d-none d-lg-flex align-items-center justify-content-center">
				<div className="text-light text-center">
					<div className="px-5 mb-4">
						<img className="d-block w-100 m-auto" style={{ maxHeight: "450px", maxWidth: "500px" }} alt="" src={heroImage} />
					</div>
					<p className="h1 m-0">
						<b>Designed for individuals</b>
					</p>
					<p className="h1 mb-3">
						<b>and businesses combined.</b>
					</p>
					<p className="fs-6 mb-0">Track your analytics and grow</p>
					<p className="fs-6">your data remotely</p>
				</div>
			</div>
		</div>
	);
}
