import React, { useCallback, useEffect, useState } from "react";
import { SiteDataColumn } from "../../models/data/site-data-column";
import UpdateColumnsRequest from "../../models/requests/UpdateColumnsRequest";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import SiteService from "../../services/session/site-service";
import SelectableDropdown from "../selectable-dropdown/selectable-dropdown";
import StatefullInput from "../statefull-input/statefull-input";
import "./form-column-config-component.scss";

export default function FormColumnConfigComponent({ formAPIKey }: { formAPIKey: string }) {
	const [columns, setColumns] = useState<SiteDataColumn[]>([]);
	const [lastSavedColumns, setLastSavedColumns] = useState<SiteDataColumn[]>(null);
	const [errors, setErrors] = useState<string>(null);
	const [saving, setSaving] = useState<boolean>(false);
	const [savingDebounce, setSavingDebounce] = useState(-1);
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	const AddNewColumn = () => {
		var newColumns = [...columns];
		newColumns.push(new SiteDataColumn("new column", true, "text"));
		var valid = ValidateColumns(newColumns, false, false);
		if (!valid) return;
		setColumns(newColumns);
	};

	const DeleteColumn = (index: number) => {
		var newColumns = [...columns];
		newColumns.splice(index, 1);
		var valid = ValidateColumns(newColumns, false, false);
		if (!valid) return;
		setColumns(newColumns);
	};

	const UpdateColumn = (column: SiteDataColumn, index: number) => {
		const newColumns = [...columns];
		newColumns[index] = column;
		var valid = ValidateColumns(newColumns);
		if (!valid) return;
		setColumns(newColumns);
	};

	const Save = useCallback(() => {
		if (JSON.stringify([...columns]) === JSON.stringify([...lastSavedColumns])) return -1;

		var _ = window.setTimeout(() => {
			setSaving(true);
			SiteService.updateColumns(
				formAPIKey,
				new UpdateColumnsRequest(JSON.parse(JSON.stringify([...columns]))),
				(newCols: SiteDataColumn[]) => {
					setLastSavedColumns(newCols);
					setSaving(false);
				},
				() => {
					setSaving(false);
				}
			);
		}, 500);
		return _;

		// eslint-disable-next-line
	}, [formAPIKey, columns]);

	const SaveDebounce = useCallback(() => {
		window.clearTimeout(savingDebounce);
		var debounceId = Save();
		setSavingDebounce(debounceId);
		// eslint-disable-next-line
	}, [Save]);

	useEffect(() => {
		var valid = ValidateColumns(columns, true);
		if (valid) SaveDebounce();
	}, [columns, SaveDebounce]);

	const ValidateColumns = (_columns: SiteDataColumn[], checkForNull = false, checkNew = true) => {
		/*
      Validate columns, cant be same name twice
    */
		var valid = true;
		var errorMessage: string = null;

		setErrors(errorMessage);

		if (_columns.length <= 0) valid = false;

		_columns.forEach((_c: SiteDataColumn) => {
			_c.valid = true;
		});

		if (checkForNull) {
			_columns.forEach((_col: SiteDataColumn, i: number) => {
				if (_col.name === "" || _col.name === null) {
					errorMessage = `Column name can't be empty`;
					_col.valid = false;
					valid = false;
				}
			});
		}

		if (checkNew) {
			_columns.forEach((_col: SiteDataColumn, i: number) => {
				var existingName = _columns.filter((_c) => _c.name !== "" && _c.name.toLowerCase() === _col.name.toLowerCase());
				if (existingName.length > 1) {
					valid = false;
					_col.valid = false;
					if (errorMessage === null) errorMessage = "Column names can't be duplicated";
				}
			});
		}

		if (!valid) setErrors(errorMessage);
		return valid;
	};

	const renderColumns = () => {
		if (columns === null) return null;
		return columns.map((c: SiteDataColumn, i: number) => {
			if (c.name === null) c.name = "";
			return (
				<div key={i}>
					{/* <p>{JSON.stringify(c)}</p> */}
					<div className={`bg-white rounded my-2 p-2 border ${c.valid ? "" : "border-danger"}`}>
						<div className={`d-block d-md-flex align-items-end justify-content-between`}>
							<div className="mx-1 py-1 flex-fill">
								<StatefullInput
									stateId={`${formAPIKey}_${i}_columnName`}
									label={"Name"}
									placeholder={"Column Name"}
									defaultValue={c.name}
									inputType={"text"}
									autocompleteType={"off"}
									onChangeCallback={(val: string) => {
										c.name = val;
										UpdateColumn(c, i);
									}}
									onPressCallback={(_: any) => {}}
									stateChangeEvent={errorState}
								/>
							</div>

							<div className="mx-1 py-1 flex-fill">
								<SelectableDropdown
									stateId={""}
									label={"Input Type"}
									options={["Text", "Date", "Dropdown", "Text Area"]}
									selectedOption={c.type}
									onChange={(val: "Text" | "Date" | "Dropdown" | "Text Area") => {
										c.type = val === "Text" ? "text" : val === "Date" ? "date" : val === 'Dropdown' ? "dropdown" : 'textarea';
										UpdateColumn(c, i);
									}}
								/>
							</div>

							{/* <div className="form-check py-1">
								<input
									id={`${formAPIKey}_${i}_rememberMeCheckbox`}
									className="form-check-input"
									type="checkbox"
									checked={c.required}
									onChange={() => {
										c.required = !c.required;
										UpdateColumn(c, i);
									}}
								/>
								<label htmlFor={`${formAPIKey}_${i}_rememberMeCheckbox`} className="form-check-label">
									Field required?
								</label>
							</div> */}

							<div className="py-1">
								<button
									className={`btn ${columns.length === 1 ? "btn-outline-light" : "btn-danger"}`}
									disabled={columns.length === 1}
									onClick={() => DeleteColumn(i)}
								>
									Delete
								</button>
							</div>
						</div>
						{c.type === "dropdown" ? (
							<>
								<div className="mx-1 py-1">
									<StatefullInput
										stateId={`${formAPIKey}_${i}_columnOptions`}
										label={"Dropdown Options"}
										placeholder={"Comma Separated values"}
										defaultValue={c.options?.join(",")}
										inputType={"text"}
										autocompleteType={"off"}
										onChangeCallback={(val: string) => {
											c.options = val.split(",");
											UpdateColumn(c, i);
										}}
										onPressCallback={(_: any) => {}}
										stateChangeEvent={errorState}
									/>
								</div>
							</>
						) : (
							<></>
						)}
					</div>
				</div>
			);
		});
	};

	useEffect(() => {
		// fetch columns
		SiteService.getColumns(formAPIKey, (_: SiteDataColumn[]) => {
			if (_ === null) _ = [];
			setLastSavedColumns(JSON.parse(JSON.stringify(_)));
			setColumns(_);
			setSaving(false);
		});
	}, [formAPIKey]);

	return (
		<div>
			<>
				<div>
					<span>Create your data inputs</span>
					<p className="text-muted">Add inputs to this site to show your data (case insensitive)</p>
				</div>
				<div>{renderColumns()}</div>
				<div className="mt-2 d-block d-md-flex align-items-center justify-content-between bg-white p-2 rounded">
					{saving ? (
						<div className="spinner-border text-success">
							<span className="visually-hidden">Loading...</span>
						</div>
					) : errors ? (
						<p className="mb-1 m-md-0 text-danger">{errors}</p>
					) : (
						<p className="mb-1 m-md-0 text-success">Form Saved.</p>
					)}
					<div className="flex-fill"></div>
					<button className={`btn m-0 w-100 w-auto btn-outline-primary`} onClick={() => AddNewColumn()}>
						Add New Column
					</button>
				</div>
			</>
		</div>
	);
}
