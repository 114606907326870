import { HTTP_CONSTANTS } from "../../constants/http-constants";
import Invoice from "../../models/data/invoice";
import { SubscriptionTier } from "../../models/data/subscription-tier";
import UserSite from "../../models/data/user-site";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";
import SiteService from "../session/site-service";

function _getCheckoutSessionForSite(site: UserSite, callback: ResponseCallback<string>): void {
	HttpService.get<{ id: string; url: string }>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/site/${site.id}`,
		(_: { id: string; url: string }) => {
			callback.success(_.url);
		},
		(err: HttpError) => {
			callback.error(err);
		},
		{
			domain: window.location.origin,
		}
	);
}

function _deleteSiteForUser(site: UserSite, callback: ResponseCallback<UserSite | null>): void {
	HttpService.delete<UserSite | null>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/site/${site.id}`,
		(_: UserSite | null) => {
			if (_ !== null) {
				SiteService.removeSite(_.id);
			}
			callback.success(_);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _getLatestInvoice(site: UserSite, callback: ResponseCallback<Invoice | null>): void {
	HttpService.get<Invoice | null>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/last-invoice/${site.id}`,
		(_: Invoice | null) => {
			callback.success(_);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _getUpcomingInvoice(site: UserSite, callback: ResponseCallback<Invoice | null>): void {
	HttpService.get<Invoice | null>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/upcoming-invoice/${site.id}`,
		(_: Invoice | null) => {
			callback.success(_);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _getUpgradeDraftInvoice(site: UserSite, subscriptionTier: SubscriptionTier, callback: ResponseCallback<Invoice | null>): void {
	HttpService.get<Invoice | null>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/upgrade-invoice/${site.id}/${subscriptionTier}`,
		(_: Invoice | null) => {
			callback.success(_);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _updatePaymentMethod(site: UserSite, callback: ResponseCallback<string>): void {
	HttpService.get<{ id: string; url: string }>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/site/${site.id}/update`,
		(_: { id: string; url: string }) => {
			callback.success(_.url);
		},
		(err: HttpError) => {
			callback.error(err);
		},
		{
			domain: window.location.origin,
		}
	);
}

function _renderPrice(minorPrice: number): string {
	const negative = minorPrice < 0;
	minorPrice = Math.abs(minorPrice);
	const majorPrice = minorPrice / 100;
	return `${negative ? "-" : ""} £${majorPrice.toFixed(2)}`;
}

const PaymentService = {
	GetCheckoutSessionForSite: _getCheckoutSessionForSite,
	CancelSubscription: _deleteSiteForUser,
	GetLatestInvoice: _getLatestInvoice,
	GetUpcomingInvoice: _getUpcomingInvoice,
	GetUpgradeDraftInvoice: _getUpgradeDraftInvoice,
	RenderPrice: _renderPrice,
	UpdatePaymentMethod: _updatePaymentMethod,
};

export default PaymentService;
