import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import IMDPModal from "../../models/interfaces/IMDPModal";
import EventEmitter from "../event/event-emitter";

function ModalServiceComponent() {
	const [modals, setModals] = useState<IMDPModal[]>([]);

	useEffect(() => {
		setModals([]);

		var modalSub = ModalService.ModalChangeEvent.subscribe((_: IMDPModal[]) => {
			setModals([..._]);
		});

		return () => {
			ModalService.ModalChangeEvent.unsubscribe(modalSub);
		};
	}, []);

	const MODAL_HTML = (key: number, id: string, modal: IMDPModal) => {
		return (
			<div key={key}>
				<Modal show={modal.active} onHide={() => modal.close()}>
					{modal.content}
				</Modal>
			</div>
		);
	};

	const MODAL_PARENT = useCallback(() => {
		return (
			<>
				<div id="root_modal_parent">
					{modals.map((m, i) => {
						if (m.active) return MODAL_HTML(i, `modal_${i}`, m);
						else return null;
					})}
				</div>
			</>
		);
	}, [modals]);

	return <>{MODAL_PARENT()}</>;
}

// SERVICE STUFF

var MODALS: IMDPModal[] = [];
var modalChangeEvent: EventEmitter<IMDPModal[]> = new EventEmitter<IMDPModal[]>();

function _OpenModal(id: string, renderComponent: JSX.Element): IMDPModal {
	var existingIndex = MODALS.findIndex((_) => _.id === id);
	if (existingIndex > -1) return null;

	var newModal = new IMDPModal(id, MODALS.length + 1, renderComponent, true, () => {
		_CloseModalById(id);
	});

	var tempModals = [...MODALS, newModal];
	MODALS = tempModals;

	modalChangeEvent.emit(MODALS);

	if (newModal == null) console.error("Failed to open modal");

	return newModal;
}

function _CloseModalById(id: string): void {
	var existingIndex = MODALS.findIndex((_) => _.id.toString() === id.toString());
	if (existingIndex === -1) {
		return;
	}
	MODALS[existingIndex].onClose.emit();
	MODALS.splice(existingIndex, 1);
	modalChangeEvent.emit(MODALS);
}

const ModalService = {
	Component: ModalServiceComponent,
	ModalChangeEvent: modalChangeEvent,
	OpenModal: _OpenModal,
	CloseModal: _CloseModalById,
};

export default ModalService;
