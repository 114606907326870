import React from "react";
import { FaTimes } from "react-icons/fa";
import ModalService from "../../services/ui/modal-service";
import "./modal-container.scss";
export default function ModalContainer({ modalId, header, Children }: { modalId: string; header: string; Children: React.ReactNode }) {
	return (
		<div className="bg-white p-3 rounded mb-2">
			<div className="d-flex align-items-center justify-content-between mb-2">
				<h3 className="mb-0">{header}</h3>
				<div
					className="d-block mb-0 h4 interact"
					onClick={() => {
						ModalService.CloseModal(modalId);
					}}
				>
					<FaTimes />
				</div>
			</div>
			{Children}
		</div>
	);
}
