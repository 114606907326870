import React, { useEffect, useState } from "react";
import "./upgrade-site-modal.scss";
import { FaBug } from "react-icons/fa";
import { SubscriptionTier, SubscriptionTierMapper } from "../../models/data/subscription-tier";
import UserSite from "../../models/data/user-site";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import { IErrorResp } from "../../models/responses/IErrorResp";
import SiteService from "../../services/session/site-service";
import UserService from "../../services/session/user-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import PricingBoard from "../pricing-board/pricing-board";
import StatefullInput from "../statefull-input/statefull-input";
import StatefullLabel from "../statefull-label/statefull-label";
import EventEmitter from "../../services/event/event-emitter";
import ConfirmationModal from "../confirmation-modal/confirmation-modal";

export default function UpgradeSiteModal({ modalId }: { modalId: string }) {
	const [currentSite, updateSite] = useState<UserSite>(SiteService.getCurrentSite());
	const [appSumoCode, setAppSumoCode] = useState<string>("");
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	useEffect(() => {
		if (!UserService.getCurrentSession().appSumoUser) ModalService.CloseModal(modalId); // non app sumo users cant see this modal
	}, [modalId]);

	const UpgradeSite = () => {
		var errors = [];

		if (appSumoCode === null || appSumoCode === "") {
			errors.push({ key: "appSumoCode", value: "AppSumo code can not be empty" });
		}

		if (errors.length > 0) {
			errorState.emit({ error: "Invalid Input", fixes: errors });
			return;
		}

		SiteService.upgradeUserSite(
			currentSite,
			appSumoCode,
			(_: UserSite) => {
				if (_ === null) {
					return;
				}

				if (_.subscriptionTier > currentSite.subscriptionTier) {
					ModalService.CloseModal(modalId);
					updateSite(_);
					SiteService.getUserSites((_sites: UserSite[]) => {
						if (window.confetti)
							window.confetti.addConfetti({
								particleCount: 100,
								spread: 70,
								origin: { y: 0.6 },
							});
						ModalService.OpenModal(
							"upgrade_confirmation",
							<ConfirmationModal
								modalId="upgrade_confirmation"
								options={{
									header: "Site Upgrade Complete",
									body: `Congratulations! Your site has updated to our ${SubscriptionTierMapper.FromTier(
										_.subscriptionTier
									)} tier!`,
									confirmText: null,
									declineText: "Okay",
								}}
								callback={() => {}}
							/>
						);
					});
				} else {
					ToastService.OpenToast(
						"start_sub_fail",
						"Failed to upgrade site",
						"If this continues please contact support",
						FaBug,
						new ToastConfig({
							iconColour: "danger",
							headerColour: "danger",
							borderColour: "danger",
						})
					);
				}
			},
			(error: IErrorResp) => {
				errorState.emit(error);
			}
		);
	};

	return (
		<>
			<div className="bg-white p-2 rounded">
				<div>
					<h3 className="mb-3">Upgrade current site.</h3>
					{currentSite.subscriptionTier === SubscriptionTier.PROFESSIONAL ? (
						<>
							<p className="text-muted m-0">
								You are unable to upgrade <strong>{currentSite.name}</strong> from{" "}
								<strong>{SubscriptionTierMapper.FromTier(currentSite.subscriptionTier)}</strong>, as its the highest tier we offer
								with standard pricing.
							</p>
							<p className="text-muted mt-4 mb-0">
								To discuss custom plans please contact us at{" "}
								<strong>
									<a href="mailto:sales@mydatapro.co.uk" className="text-decoration-underline">
										sales@mydatapro.co.uk
									</a>
								</strong>
							</p>
						</>
					) : (
						<>
							<p className="text-muted m-0">
								Use your AppSumo code to upgrade <strong>{currentSite.name}</strong> from{" "}
								<strong>{SubscriptionTierMapper.FromTier(currentSite.subscriptionTier)}</strong> to{" "}
								<strong>{SubscriptionTierMapper.FromTier(currentSite.subscriptionTier + 1)}</strong>.
							</p>
							<StatefullLabel stateId="global" stateChangeEvent={errorState} />
							<div className="mt-2">
								<div className="rounded d-block">
									{UserService.getCurrentSession().appSumoUser ? (
										<>
											<StatefullInput
												stateId={`appSumoCode`}
												label={"Enter your redemption code from AppSumo 🌮"}
												placeholder={"AppSumo Code"}
												defaultValue={appSumoCode}
												inputType={"text"}
												autocompleteType={"off"}
												onChangeCallback={(val: string) => {
													setAppSumoCode(val);
												}}
												onPressCallback={(_: any) => {}}
												stateChangeEvent={errorState}
											/>

											<div className="py-1 d-flex align-items-center justify-content-end gap-2">
												<span className="flex-space d-block mt-2"></span>
												<button
													className="d-block btn btn-outline-danger"
													onClick={() => {
														ModalService.CloseModal(modalId);
													}}
												>
													Cancel
												</button>
												<button
													className="d-block btn btn-primary"
													onClick={() => {
														UpgradeSite();
													}}
												>
													Confirm
												</button>
											</div>

											<div className="mt-1 d-flex justify-content-between align-items-center gap-4">
												<PricingBoard
													customClass="w-100"
													showButtons={false}
													showPrice={false}
													showTitle={false}
													activeTier={currentSite.subscriptionTier}
												/>

												<PricingBoard
													customClass="w-100"
													showButtons={false}
													showPrice={false}
													showTitle={false}
													activeTier={SubscriptionTierMapper.ToTier(
														SubscriptionTierMapper.FromTier(currentSite.subscriptionTier + 1)
													)}
												/>
											</div>
										</>
									) : (
										<></>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
