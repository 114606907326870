import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserService from "../../services/session/user-service";
import logo from "../../assets/images/mdp_logo_black.png";
import "./register-page.scss";
import RegisterRequest from "../../models/requests/RegisterRequest";
import StatefullInput from "../../components/statefull-input/statefull-input";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import StatefullLabel from "../../components/statefull-label/statefull-label";
import heroImage from "../../assets/images/HeroImage_3.png";
import RefererService from "../../services/ui/referer-service";

export default function RegisterPage({ useReferer = false }: { useReferer?: boolean }) {
	const navigate = useNavigate();
	const [fullName, updateFullName] = useState("");
	const [email, updateEmail] = useState("");
	const [password, updatePassword] = useState("");
	const [passwordRepeat, updatePasswordRepeat] = useState("");
	const [registerRequest, updateRegisterRequest] = useState(null);
	const [acceptTerms, updateAcceptTerms] = useState(false);
	const [termsRequired, setTermsRequired] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	let { refererName } = useParams();

	const keyDownHandler = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			MakeRegisterRequest();
		}
	};

	useEffect(() => {
		var updatedRegisterRequest = new RegisterRequest(fullName, email, password, passwordRepeat, useReferer ? refererName : null);
		updateRegisterRequest(updatedRegisterRequest);
	}, [fullName, email, password, passwordRepeat, useReferer, refererName]);

	const MakeRegisterRequest = () => {
		setLoading(true);
		if (!acceptTerms) {
			setTermsRequired(true);
			setLoading(false);
			return;
		}
		setTermsRequired(false);
		UserService.register(registerRequest, (success: boolean, error: IErrorResp) => {
			if (success) {
				navigate("/dashboard");
				setLoading(false);
			} else {
				setLoading(false);
				errorState.emit(error);
			}
		});
	};

	return (
		<div className="pg-100 bg-light d-flex align-items-stretch">
			<div className="bg-light col-12 col-lg-6 d-flex align-items-center justify-content-center">
				<div className="w-75 m-auto">
					<div>
						<div className="mb-5">
							<Link to={"/"}>
								<img src={logo} height="50" className="d-inline-block align-top" alt="mdp logo" />
							</Link>
							{useReferer ? RefererService.InAssociationComponent(refererName) : null}
						</div>
						<p className="h3">Create account</p>
						<p className="lead">Get started with MDP by creating a FREE account.</p>
					</div>

					<form className="mt-4">
						<div className="mb-3">
							<StatefullInput
								stateId="fullName"
								label="Name"
								defaultValue={fullName}
								inputType="text"
								autocompleteType="name"
								onChangeCallback={(val: string) => updateFullName(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>

						<div className="mb-3">
							<StatefullInput
								stateId="email"
								label="E-mail"
								defaultValue={email}
								inputType="text"
								autocompleteType="email"
								onChangeCallback={(val: string) => updateEmail(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>

						<div className="mb-3">
							<StatefullInput
								stateId="password"
								label="Password"
								defaultValue={password}
								inputType="password"
								autocompleteType="new-password"
								onChangeCallback={(val: string) => updatePassword(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>

						<div className="mb-3">
							<StatefullInput
								stateId="repeatPassword"
								label="Repeat Password"
								defaultValue={passwordRepeat}
								inputType="password"
								autocompleteType="new-password"
								onChangeCallback={(val: string) => updatePasswordRepeat(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>
					</form>

					<div className="mt-4">
						<StatefullLabel stateId="global" stateChangeEvent={errorState} />
					</div>
					<div className="border-bottom mt-2 mb-4"></div>

					<div className="d-flex justify-content-between align-items-center">
						<div>
							<div className="form-check">
								<input
									id="rememberMeCheckbox"
									className="form-check-input"
									type="checkbox"
									checked={acceptTerms}
									onChange={() => {
										updateAcceptTerms(!acceptTerms);
									}}
								/>
								<label htmlFor="rememberMeCheckbox" className="form-check-label">
									I've read and accept the{" "}
									<a className="text-success" href="/terms" target={"_blank"}>
										<strong>Terms & Conditions</strong>
									</a>
								</label>
							</div>
							{termsRequired ? (
								<label className="form-check-label form-label text-danger small">
									Terms and conditions must be accepted to continue.
								</label>
							) : null}
						</div>

						<div className="">
							<button className="btn btn-success" disabled={loading} onClick={() => MakeRegisterRequest()}>
								{loading ? (
									<>
										<span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
										Loading...
									</>
								) : (
									<>Create my account</>
								)}
							</button>
						</div>
					</div>

					<div className="text-center mt-4">
						<span className="me-2">Already have an account?</span>
						<Link to="/auth/login">
							<span className="text-success">
								<strong>Sign in</strong>
							</span>
						</Link>
					</div>
				</div>
			</div>

			<div className="bg-success col-6 lg-6 d-none d-lg-flex align-items-center justify-content-center">
				<div className="text-light text-center">
					<div className="px-5 mb-4">
						<img className="d-block w-100 m-auto" style={{ maxHeight: "450px", maxWidth: "500px" }} alt="" src={heroImage} />
					</div>
					<p className="h1 m-0">
						<b>Turn your ideas</b>
					</p>
					<p className="h1 mb-3">
						<b>into reality.</b>
					</p>
					<p className="fs-6 mb-0">Consistent excellence and quality</p>
					<p className="fs-6">across all platforms</p>
				</div>
			</div>
		</div>
	);
}
