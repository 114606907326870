import React from "react";
import "./dashboard-navigation-component.scss";
import logo from "../../assets/images/logo_icon_black.png";
import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

export class NavigationGroup {
	constructor(public id: number, public groupName: string, public navItems: NavigationItem[]) {}
}

export class NavigationItem {
	constructor(
		public id: number,
		public icon: JSX.Element,
		public component: JSX.Element,
		public route: string = "",
		public canHover: boolean = true,
		public iconClickAction: boolean = false,
		public onClick: () => void = () => {}
	) {}
}

function NavGroup({
	navGroup,
	curPath,
	setOpenState,
	lastGroup,
}: {
	navGroup: NavigationGroup;
	curPath: string;
	setOpenState: (_: boolean) => void;
	lastGroup: boolean;
}) {
	return (
		<div key={navGroup.id} className="nav-group" data-last={lastGroup}>
			{navGroup.groupName !== "" ? <span className="group-name text-black-50 tiny d-block mx-2">{navGroup.groupName}</span> : null}
			{navGroup.navItems.map((item: NavigationItem) => {
				if (item === null) return null;
				return <NavItem key={item.id} navItem={item} curPath={curPath} setOpenState={(_: boolean) => setOpenState(_)} />;
			})}

			{lastGroup ? null : <div className="mx-1 my-2 border-bottom" />}
		</div>
	);
}

function NavItem({ navItem, curPath, setOpenState }: { navItem: NavigationItem; curPath: string; setOpenState: (_: boolean) => void }) {
	return (
		<div
			className={`nav-item 
      ${navItem.canHover ? "" : "no-hover"} 
      ${navItem.route && navItem.route === curPath ? "active" : ""}`}
			onClick={() => {
				if ((navItem.route === "" || navItem.route === null) && navItem.iconClickAction) {
					navItem.onClick();
				} else {
					setOpenState(true);
				}
			}}
		>
			<div className="nav-icon">
				<span className="h3 m-0 text-secondary">{navItem.icon}</span>
			</div>
			{navItem.route === "" || navItem.route === null ? (
				<div
					onClick={() => {
						setOpenState(false);
						navItem.onClick();
					}}
					className="nav-content"
				>
					<div className="inner">{navItem.component}</div>
				</div>
			) : (
				<Link
					className="nav-content"
					to={navItem.route}
					onClick={(e: React.MouseEvent) => {
						e.stopPropagation();
						setOpenState(false);
					}}
				>
					<div className="inner">{navItem.component}</div>
				</Link>
			)}
		</div>
	);
}

export default function DashboardNavigationComponent({ navGroups }: { navGroups: NavigationGroup[] }) {
	const [sidenavOpen, setOpenState] = useState(false);
	const location = useLocation();

	if (navGroups == null) return <div></div>;

	return (
		<div>
			<div className="sidenav-push"></div>
			<div className={`sidenav-overlay ${sidenavOpen ? "open" : "closed"}`} onClick={() => setOpenState(false)}></div>
			<div
				className={`sidenav bg-white align-self-stretch
        ${sidenavOpen ? "open" : ""}`}
			>
				<div className="sidenav-toggle">
					<button
						className="rounded-circle bg-white shadow-sm text-primary border-light border text-center"
						onClick={() => setOpenState(!sidenavOpen)}
					>
						{sidenavOpen ? <FaChevronLeft className="small" /> : <FaChevronRight className="small" />}
					</button>
				</div>

				<div className="nav-container d-flex flex-column h-100">
					<div className="nav-header px-2 py-2">
						<div className="header-nav-item no-hover no-overlay">
							<img src={logo} height="30" className="d-inline-block align-top" alt="mdp logo" />
							<div className="nav-content">
								<div className="d-flex flex-column">
									<span className="h6 mb-0">
										<strong>MDP</strong>
									</span>
									<span className="text-muted small">Dashboard</span>
								</div>
							</div>
						</div>
					</div>

					<div className="border-bottom mx-1 mb-2"></div>

					<div className="flex-fill">
						{navGroups.map((group: NavigationGroup, index: number) => {
							if (group === null) return <></>;
							return (
								<NavGroup
									key={group.id}
									navGroup={group}
									curPath={location.pathname}
									setOpenState={setOpenState}
									lastGroup={index === navGroups.length - 1}
								/>
							);
						})}
					</div>

					{/* <div className="nav-footer">
						<div>
							<NavItem
								navItem={new NavigationItem(0, <FaSignOutAlt />, <span>Sign Out</span>, "", true, () => logoutCallback())}
								curPath={location.pathname}
								setOpenState={setOpenState}
							/>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
}
