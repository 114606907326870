import React, { useCallback, useState } from "react";
import { FaPen, FaTimes } from "react-icons/fa";
import UserSiteForm from "../../models/data/user-site-form";
import SiteService from "../../services/session/site-service";
import ModalService from "../../services/ui/modal-service";
import FormColumnConfigComponent from "../form-column-config-component/form-column-config-component";
import StatefullInput from "../statefull-input/statefull-input";
import "./form-config-modal.scss";

export default function FormConfigModal({
	modalId,
	form,
	deleteCallback,
}: {
	modalId: string;
	form: UserSiteForm;
	deleteCallback: (form: UserSiteForm) => void;
}) {
	const [formName, setFormName] = useState(form.name);
	const [editMode, setEditMode] = useState(false);

	const saveName = useCallback(() => {
		if (formName === form.name) {
			setEditMode(false);
			return;
		}

		form.name = formName;
		SiteService.updateSiteForm(
			form,
			(updatedForm: UserSiteForm) => {
				setEditMode(false);
			},
			() => {}
		);
	}, [formName, form]);

	const hasChanged = useCallback(() => {
		return formName !== form.name;
	}, [formName, form]);

	const renderNameEdit = () => {
		return (
			<div className="d-flex justify-content-between align-items-center">
				<StatefullInput
					stateId={`${form.id}_name`}
					label={null}
					placeholder="Form Name"
					defaultValue={formName}
					inputType="text"
					autocompleteType="off"
					onChangeCallback={(val: string) => {
						setFormName(val);
					}}
					onPressCallback={() => {}}
					stateChangeEvent={null}
				/>
				<button
					className={`btn ${hasChanged() ? "btn-primary" : "btn-danger"} ms-1`}
					onClick={() => {
						saveName();
					}}
				>
					{hasChanged() ? "Save" : "Cancel"}
				</button>
			</div>
		);
	};

	return (
		<div className="bg-white p-3 rounded mb-2">
			<div className="mb-3">
				<div className="d-flex justify-content-between align-items-center">
					{editMode ? (
						<div className="d-flex justify-content-start align-items-center flex-fill">{renderNameEdit()}</div>
					) : (
						<div className="d-flex justify-content-start align-items-center flex-fill">
							<div
								className="d-flex justify-content-start align-items-center interact"
								onClick={() => {
									setEditMode(!editMode);
								}}
							>
								<h4 className="mb-0">{formName}</h4>

								<FaPen className="fs-6 ms-3" />
							</div>
						</div>
					)}

					<button
						className="btn d-none btn-outline-danger me-2"
						onClick={() => {
							deleteCallback(form);
							ModalService.CloseModal(modalId);
						}}
					>
						Delete Form
					</button>

					<div className="d-block mb-0 h4 interact" onClick={() => ModalService.CloseModal(modalId)}>
						<FaTimes />
					</div>
				</div>
			</div>
			<div>
				{/* FORM COLUMN EDITING */}
				<FormColumnConfigComponent formAPIKey={form.apiKey} />
			</div>
		</div>
	);
}
