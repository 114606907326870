import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "./nav-bar-component.scss";

import logo from "../../assets/images/mdp_logo_black.png";
import { Link } from "react-router-dom";

export default function NavBarComponent() {
	return (
		<Navbar className="bg-light border-bottom" expand="lg" variant="light" fixed="top">
			<Container fluid className="page-width">
				<Navbar.Brand href="/#home">
					<img src={logo} height="30" className="d-inline-block align-top" alt="mdp logo" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />

				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="d-flex me-auto">
						<Nav.Link href="/#how">How It Works</Nav.Link>
						<Nav.Link href="/#features">Features</Nav.Link>
						<Nav.Link href="/#pricing">Pricing</Nav.Link>
						<Nav.Link href="/#testimonials">Testimonials</Nav.Link>
					</Nav>

					<div className="d-flex align-items-center">
						<Link to="/auth/login">
							<button className="me-2 btn btn-outline-primary">Login</button>
						</Link>
						<Link to="/auth/register">
							<button className="btn btn-primary">Sign Up</button>
						</Link>
					</div>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
