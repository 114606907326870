import React from "react";
import { FaCheck, FaClipboard, FaShopify, FaWix, FaWordpress } from "react-icons/fa";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import ToastService from "../../services/ui/toast-service";
import "./integration-guide-page.scss";
export default function IntegrationGuidePage() {
	const copyCodeSnippet = () => {
		const codeSnippet = `<div id="mdp_*APIKEY*"></div>
<script script async src="https://www.mydatapro.co.uk/api/v1/*APIKEY*"></script>`;
		navigator.clipboard.writeText(codeSnippet);

		ToastService.OpenToast(
			"copied_api_key",
			"Code copied!",
			"Code snippet is now in your clipboard",
			FaCheck,
			new ToastConfig({ autoHide: true, iconColour: "success", headerColour: "success", borderColour: "success" })
		);
	};

	const copyPremiumCodeSnippet = () => {
		const codeSnippet = `<form id="mdp_FORM_*APIKEY*"></form>
<script script async src="https://www.mydatapro.co.uk/api/v1/*APIKEY*"></script>`;
		navigator.clipboard.writeText(codeSnippet);

		ToastService.OpenToast(
			"copied_api_key",
			"Code copied!",
			"Code snippet is now in your clipboard",
			FaCheck,
			new ToastConfig({ autoHide: true, iconColour: "success", headerColour: "success", borderColour: "success" })
		);
	};

	return (
		<div className="p-3">
			<div className="mb-4">
				<div className="d-flex">
					<div className="border-1 border-secondary border-bottom">
						<p className="h4 mb-0 text-secondary pe-2">Standard Integration</p>
					</div>
					<div className="flex-fill border-1 border-bottom"></div>
					<div className="border-1 border-secondary border-bottom d-flex align-items-end">
						<p className="h6 mb-0 text-secondary pe-2">Click the code block to copy</p>
					</div>
				</div>

				<div className="mt-2 mb-2 p-2 border bg-light border-secondary rounded interact" onClick={copyCodeSnippet}>
					<pre className="mb-0">
						<code>&lt;div id="mdp_*APIKEY*"&gt;&lt;/div&gt;</code>
					</pre>
					<pre className="mb-0">
						<code>&lt;script async src="https://www.mydatapro.co.uk/api/v1/*APIKEY*"&gt;&lt;/script&gt;</code>
					</pre>
				</div>

				<div className="d-none">
					<div className="d-flex">
						<div className="border-1 border-secondary border-bottom">
							<p className="h4 mb-0 text-secondary pe-2">Custom Integration</p>
						</div>
						<div className="flex-fill border-1 border-secondary border-bottom"></div>
						<div className="border-1 border-secondary border-bottom d-flex align-items-end">
							<p className="h6 mb-0 text-secondary pe-2">For premium tier and above</p>
						</div>
					</div>
					<div className="mt-2 p-2 border bg-white rounded interact shadow-sm" onClick={copyPremiumCodeSnippet}>
						<pre className="mb-0">
							<code>&lt;form id="mdp_FORM_*APIKEY*"&gt;&lt;/form&gt;</code>
						</pre>
						<pre className="mb-0">
							<code>&lt;script async src="https://www.mydatapro.co.uk/api/v1/*APIKEY*"&gt;&lt;/script&gt;</code>
						</pre>
					</div>
				</div>

				<p className="text-muted mt-2">
					Replace *APIKEY* with your forms API key (<FaClipboard /> symbol on the 'Forms' page)
				</p>

				<h5 className="mt-4">Where to add?</h5>
				<p className="mb-0">
					Paste the '<b>div</b>' line where you would like the for to display on your page
				</p>
				<p>
					Paste the '<b>script</b>' line at the bottom of yor file just before the closing '<b>body</b>' tag
				</p>

				<h5 className="mt-4">Whats to come?</h5>
				<p className="mb-0">Were working hard on our next release that will give you even more customisation over your forms!</p>
				<p>This includes custom styling, new input types or a completely custom integration option.</p>
			</div>

			{/* <div className="mb-4">
				<div className="d-flex">
					<div className="border-1 border-secondary border-bottom">
						<p className="h4 mb-0 text-secondary pe-2">Next Steps</p>
					</div>
					<div className="flex-fill border-1 border-secondary border-bottom"></div>
				</div>

				<div>
					<p>Now your form is integrated into your site, youre ready to start getting responses!</p>

					<h5>Whats to come</h5>
					<p>Were working hard on our next release that will give you even more customisation</p>
				</div>
			</div> */}

			<div className="mb-4 d-none">
				<div className="d-flex">
					<div className="border-1 border-secondary border-bottom">
						<p className="h4 mb-0 text-secondary pe-2">Brand Specific Integration</p>
					</div>
					<div className="flex-fill border-1 border-secondary border-bottom"></div>
					<div className="border-1 border-secondary border-bottom">
						<p className="h4 mb-0 text-secondary pe-2">Coming Soon</p>
					</div>
				</div>

				<div className="d-flex gap-2 mt-2">
					<div className="border shadow-sm p-3 flex-fill-even">
						<div className="d-flex align-items-center justify-content-center">
							<FaShopify className="h4 mb-0" />
							<h4 className="mb-0 ms-1">Shopify</h4>
						</div>
						<div>
							<p className="mb-0 mt-2">Coming Soon</p>
						</div>
					</div>

					<div className="border shadow-sm p-3 flex-fill-even">
						<div className="d-flex align-items-center justify-content-center">
							<FaWix className="h4 mb-0" />
							<h4 className="mb-0 ms-1">Wix</h4>
						</div>
						<div>
							<p className="mb-0 mt-2">Coming Soon</p>
						</div>
					</div>

					<div className="border shadow-sm p-3 flex-fill-even">
						<div className="d-flex align-items-center justify-content-center">
							<FaWordpress className="h4 mb-0" />
							<h4 className="mb-0 ms-1">Wordpress</h4>
						</div>
						<div>
							<p className="mb-0 mt-2">Coming Soon</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
