import React, { useCallback, useEffect, useState } from "react";
import { FaCloud, FaCogs, FaHome, FaLayerGroup, FaLock, FaPlus, FaUserSecret, FaWpforms } from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CreateSiteModal from "../../components/create-site-modal/create-site-modal";
import DashboardHeaderComponent from "../../components/dashboard-header-component/dashboard-header-component";
import DashboardNavigationComponent, {
	NavigationGroup,
	NavigationItem,
} from "../../components/dashboard-navigation-component/dashboard-navigation-component";
import SiteSelectDropdownComponent from "../../components/site-select-dropdown-component/site-select-dropdown-component";
import UserSite from "../../models/data/user-site";
import SiteService from "../../services/session/site-service";
import UserService from "../../services/session/user-service";
import ModalService from "../../services/ui/modal-service";
import "./dashboard-page.scss";
import UpgradeSiteModal from "../../components/upgrade-site-modal/upgrade-site-modal";

export default function DashboardPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const [hasSites, setHasSites] = useState<boolean>(SiteService.getCurrentSite() != null);
	const [navigationGroup, setNavGroups] = useState<NavigationGroup[]>();
	const [currentSite, setCurrentSite] = useState<UserSite>(SiteService.getCurrentSite());

	const genNav = useCallback(
		(_withSites: boolean) => {
			if (UserService.getCurrentSession() === null) {
				setNavGroups([]);
				return;
			}
			var _nav =
				_withSites && currentSite !== null && currentSite !== undefined
					? currentSite.active
						? [
								new NavigationItem(0, <FaCloud />, <SiteSelectDropdownComponent />, "", false),
								new NavigationItem(1, <FaHome />, <span>Home</span>, "/dashboard"),
								new NavigationItem(2, <FaWpforms />, <span>Forms</span>, "/dashboard/configuration", true),
								UserService.getCurrentSession().appSumoUser
									? null
									: new NavigationItem(3, <FaCogs />, <span>Site Settings</span>, "/dashboard/settings", true),
						  ]
						: [
								new NavigationItem(0, <FaCloud />, <SiteSelectDropdownComponent />, "", false),
								UserService.getCurrentSession().appSumoUser
									? null
									: new NavigationItem(1, <FaCogs />, <span>Site Settings</span>, "/dashboard/settings", true),
						  ]
					: [new NavigationItem(0, <FaLock />, <span>No active sites</span>, "", false)];

			var _navGroups = [
				new NavigationGroup(0, "MENU", _nav),
				new NavigationGroup(1, "TOOLS", [
					new NavigationItem(0, <FaPlus />, <span>Add Site</span>, null, true, true, () => {
						// OPEN MODAL TO ADD NEW SITE
						ModalService.OpenModal("new_site_modal", <CreateSiteModal modalId="new_site_modal" />);
					}),
					UserService.getCurrentSession().appSumoUser && currentSite !== null && currentSite !== undefined
						? new NavigationItem(1, <FaLayerGroup />, <span>Upgrade Site</span>, null, true, true, () => {
								// OPEN MODAL TO ADD NEW SITE
								ModalService.OpenModal("upgrade_site_modal", <UpgradeSiteModal modalId="upgrade_site_modal" />);
						  })
						: null,
					UserService.getCurrentSession().accountType === 1
						? new NavigationItem(2, <FaUserSecret />, <span>Admin</span>, "/admin", true)
						: null,
				]),
			];
			setNavGroups(_navGroups);
		},
		[currentSite]
	);

	const routeProtect = useCallback(() => {
		if (location.pathname !== "/dashboard" && location.pathname !== "/dashboard/account" && location.pathname !== '/dashboard/settings') {
			console.log("Invalid route", location.pathname, 're-routing to dashboard...');
			navigate("/dashboard");
		}
	}, [location, navigate]);

	useEffect(() => {
		SiteService.getUserSites((_: UserSite[]) => {
			if (_ === null) routeProtect();
		});
	}, [routeProtect]);

	useEffect(() => {
		genNav(hasSites);
	}, [hasSites, genNav]);

	useEffect(() => {
		genNav(SiteService.getCurrentSite() != null);
		var subscription = SiteService.siteUpdatedEvent.subscribe((_: UserSite) => {
			setHasSites(SiteService.getCurrentSite() != null);
			setCurrentSite(_);

			if (_ === null || _.active === false) routeProtect();
		});

		return () => {
			SiteService.siteUpdatedEvent.unsubscribe(subscription);
		};
	}, [genNav, location, navigate, routeProtect]);

	// PAGE AUTHGUARD
	useEffect(() => {
		const token = UserService.getToken();
		if (token === "" || token === undefined) {
			navigate("/auth/login");
		}
	}, [navigate]);

	return (
		<div className="pg-100 bg-light d-flex align-items-stretch justify-content-between">
			<DashboardNavigationComponent navGroups={navigationGroup} />
			<div className="flex-fill d-flex flex-column w-100 overflow-hidden">
				<DashboardHeaderComponent
					logoutCallback={() =>
						UserService.logout(() => {
							navigate("/auth/login");
						})
					}
				/>
				<div className="p-2 flex-fill overflow-visible page-width">{<Outlet />}</div>
			</div>
		</div>
	);
}
