import React from "react";
import "./setup-guide-page.scss";
import NavBarComponent from "../../components/nav-bar-component/nav-bar-component";
export default function SetupGuidePage() {
	return (
		<div>
			<NavBarComponent />
			<div className="header-space">
				<div className="page-width">
					<h3 className="mt-4">MDP Setup Guide</h3>
					<p>Get set-up with MyDataPro using this quick and easy guide</p>

					<div></div>
				</div>
			</div>
		</div>
	);
}
