import React, { useEffect, useState } from "react";
import "./selectable-dropdown.scss";
export default function SelectableDropdown({
	stateId,
	label = null,
	options,
	selectedOption,
	onChange,
}: {
	stateId: string;
	label?: string;
	options: any[];
	selectedOption: any;
	onChange: (val: any) => void;
}) {
	const [_options, setOptions] = useState(options);
	const [_selected, setSelected] = useState(selectedOption);

	const changeHandler = (val: any) => {
		onChange(val);
		setSelected(val);
	};

	useEffect(() => {
		setOptions(options);
		setSelected(_selected);
	}, [_selected, options]);

	useEffect(() => {
		setOptions(options);
		setSelected(selectedOption);
	}, [stateId, options, selectedOption]);

	return (
		<div>
			{label == null ? null : (
				<label htmlFor={`${stateId}Input`} className="form-label mb-0">
					{label}
				</label>
			)}
			<div className="dropdown" key={stateId}>
				<button
					className={`${
						_options.length === 0 ? "disabled" : null
					} btn bg-light text-dark dropdown-toggle w-100 dropdown-selector d-flex align-items-center justify-content-between`}
					type={"button"}
					id={`DropdownButton`}
					data-bs-toggle={"dropdown"}
					aria-expanded={"false"}
				>
					<span className="me-2 ps-0 pe-4">{_selected === null ? "LOADING..." : _options.length === 0 ? "NO SITE" : _selected}</span>
				</button>

				<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="DropdownButton">
					{_options.map((option: string, i: number) => {
						return (
							<li key={i}>
								<button className="dropdown-item" onClick={() => changeHandler(option)}>
									{option}
								</button>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
}
