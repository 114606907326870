import React, { useEffect, useState } from "react";
import "./website-form.scss";
import logo from "../../assets/images/mdp_logo_black.png";
import IntegrationService from "../../services/session/integration-service";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import StatefullLabel from "../../components/statefull-label/statefull-label";
import FormDetail from "../../models/data/form-detail";
import { SiteDataColumn } from "../../models/data/site-data-column";
import StatefullInput from "../statefull-input/statefull-input";
import ToastService from "../../services/ui/toast-service";
import { FaLock } from "react-icons/fa";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import SelectableDropdown from "../selectable-dropdown/selectable-dropdown";

export default function WebsiteForm({ APIKEY, hostName, preview }: { APIKEY: string; hostName: string; preview: boolean }) {
	const [formData, setFormData] = useState<any>({});
	const [hasSubmitted, setSubmitState] = useState(false);
	const [loading, setLoading] = useState(true);
	const [formDetail, setFormDetail] = useState<FormDetail>(null);
	const [formDirty, setFormDirty] = useState(false);

	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());
	const [resetEvent] = useState<EventEmitter<void>>(new EventEmitter<void>());
	const [dirtyEvent] = useState<EventEmitter<void>>(new EventEmitter<void>());

	useEffect(() => {
		const d = dirtyEvent.subscribe(() => {
			if (formDirty || preview) return;
			setFormDirty(true);

			IntegrationService.DirtyForm(APIKEY);
		});
		return () => {
			dirtyEvent.unsubscribe(d);
		};
	}, [dirtyEvent, formDirty, APIKEY, preview]);

	const keyDownHandler = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			SubmitForm();
		}
	};

	const SubmitForm = () => {
		if (preview) {
			ToastService.OpenToast(
				"no_submit",
				"Can't submit in preview mode",
				"Submissions are disabled in preview mode.",
				FaLock,
				new ToastConfig().warning()
			);
			return;
		}
		IntegrationService.SubmitForm(APIKEY, formData, {
			success(_) {
				setSubmitState(true);
				setTimeout(() => {
					setFormData({});
					setSubmitState(false);
					resetEvent.emit();
				}, 5000);
			},
			error(err) {
				setSubmitState(false);
			},
		});
	};

	useEffect(() => {
		IntegrationService.GetFormDetail(APIKEY, hostName, {
			success(_) {
				setFormDetail(_);
				setSubmitState(false);
				setLoading(false);
			},
			error(err) {
				// hide all UI, failed response
				setLoading(false);
			},
		});
	}, [APIKEY, hostName]);

	function capitalizeFirstLetter(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	useEffect(() => {
		setTimeout(() => {
			const message = JSON.stringify({
				channel: "FROM_INTEGRATION",
				data: {
					height: formDetail === null ? 0 : window.document.body.scrollHeight,
				},
			});
			window.parent.postMessage(message, "*");
		}, 0);
	}, [formDetail]);

	const renderFormRows = () => {
		if (formDetail === null) return <></>;
		return (<>
			<style>{formDetail.customCSS}</style>
			{formDetail.columns.map((c: SiteDataColumn, i: number) => {
				const id = `${APIKEY}_C_${c.name}_${i}`;
				return (
					<div key={i} className="mb-3">
						{c.type === "dropdown" ? (
							<SelectableDropdown
								stateId={id}
								label={capitalizeFirstLetter(c.name)}
								options={c.options}
								selectedOption={c.options === undefined || c.options.length === 0 ? "" : c.options[0]}
								onChange={(val: string) => {
									const t_data = { ...formData };
									t_data[c.name] = val;
									setFormData(t_data);
								}}
							/>
						) : (
							<StatefullInput
								required={c.required}
								stateId={id}
								label={capitalizeFirstLetter(c.name)}
								defaultValue=""
								inputType={c.type}
								autocompleteType="text"
								onChangeCallback={(val: string) => {
									const t_data = { ...formData };
									t_data[c.name] = val;
									setFormData(t_data);
								}}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
								resetEvent={resetEvent}
								dirtyEvent={dirtyEvent}
							/>
						)}
					</div>
				);
			})}
		</>);
	};

	return (
		<div className="p-3">
			{loading ? (
				<div className="text-center">
					<div className="m-auto spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
						<span className="visually-hidden">Loading...</span>
					</div>
					<h4>Loading...</h4>
				</div>
			) : (
				<>
					{formDetail === null ? (
						<></>
					) : (
						<>
							{renderFormRows()}
							<div>
								<StatefullLabel stateId="global" stateChangeEvent={errorState} />
							</div>
							<div className="mt-3">
								{hasSubmitted ? (
									<button className="conf btn btn-outline-primary w-100" disabled>
										Thank You!
									</button>
								) : (
									<button onClick={() => SubmitForm()} className="conf btn btn-primary w-100">
										Submit
									</button>
								)}
							</div>
							{formDetail.integrationMode === "custom" ? (
								<></>
							) : (
								<a
									href="https://mydatapro.co.uk"
									rel="noreferrer"
									target={"_blank"}
									className="mt-3 d-flex align-items-center justify-content-center text-center"
								>
									<span>Powered By</span>
									<img src={logo} alt="mdp logo" className="ms-2 d-block" style={{ width: "80px" }} />
								</a>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
}
