import React from "react";
import { FaRegGem, FaCheckCircle, FaTimesCircle, FaCookieBite } from "react-icons/fa";
import { Link } from "react-router-dom";
import { SubscriptionTier } from "../../models/data/subscription-tier";
import "./pricing-board.scss";

class TierFeature {
	constructor(public text: string, public active: boolean = true, public tooltip: string = null) {}
}

class Tier {
	constructor(
		public tierLevel: SubscriptionTier,
		public iconColour: string,
		public title: string,
		public sub: string,
		public pricePrefix: string,
		public price: number | string,
		public priceSuffix: string,
		public features: TierFeature[],
		public accent: boolean,
		public buttonClass: string
	) {}
}

export default function PricingBoard({
	showButtons,
	showTitle,
	activeTier,
	showTrialText = false,
	showPrice = true,
	customClass,
}: {
	showButtons?: boolean;
	showTitle?: boolean;
	activeTier?: SubscriptionTier;
	showTrialText?: boolean;
	showPrice?: boolean;
	customClass?: string;
}) {
	const tiers: Tier[] = [
		{
			tierLevel: SubscriptionTier.FREE,
			iconColour: "text-success",
			title: "Free",
			sub: "Perfect for hobbyists",
			pricePrefix: "£",
			price: "0",
			priceSuffix: "pm",
			features: [
				new TierFeature("50 submissions/mo", true),
				new TierFeature("Simple integration", true),
				new TierFeature("1 form per site", true),
				new TierFeature("Data visualisation", true),
				new TierFeature("Form metrics", false),
				// new TierFeature("Two Factor Authentication", false),
				new TierFeature("Manual exports in all formats", false),
				new TierFeature("Custom integration", false),
				new TierFeature("AI Access", false),
				new TierFeature("Scheduled exports", false),
			],
			accent: false,
			buttonClass: "btn-success",
		},
		{
			tierLevel: SubscriptionTier.STANDARD,
			iconColour: "text-danger",
			title: "Standard",
			sub: "Perfect for startups",
			pricePrefix: "£",
			price: 5,
			priceSuffix: "pm",
			features: [
				new TierFeature("500 submissions/mo", true),
				new TierFeature("Simple integration", true),
				new TierFeature("2 form per site", true),
				new TierFeature("Data visualisation", true),
				new TierFeature("Form metrics", true),
				// new TierFeature("Two Factor Authentication", true),
				new TierFeature("Manual exports (csv only)", true),
				new TierFeature("Custom integration", false),
				new TierFeature("AI Access", false),
				new TierFeature("Scheduled exports", false),
			],
			accent: false,
			buttonClass: "btn-outline-danger",
		},
		{
			tierLevel: SubscriptionTier.PREMIUM,
			iconColour: "text-warning",
			title: "Premium",
			sub: "Our best deal",
			pricePrefix: "£",
			price: 15,
			priceSuffix: "pm",
			features: [
				new TierFeature("5,000 submissions/mo", true),
				new TierFeature("Simple integration", true),
				new TierFeature("5 forms per site", true),
				new TierFeature("Data visualisation", true),
				new TierFeature("Form metrics", true),
				// new TierFeature("Two Factor Authentication", true),
				new TierFeature("Manual exports in all formats", true),
				new TierFeature("Custom integration", true),
				new TierFeature("AI Access", true),
				new TierFeature("Scheduled exports", false),
			],
			accent: true,
			buttonClass: "btn-primary",
		},
		{
			tierLevel: SubscriptionTier.PROFESSIONAL,
			iconColour: "text-info",
			title: "Professional",
			sub: "Large scale projects",
			pricePrefix: "£",
			price: 75,
			priceSuffix: "pm",
			features: [
				new TierFeature("20,000 submissions/mo", true),
				new TierFeature("Simple integration", true),
				new TierFeature("20 forms per site", true),
				new TierFeature("Data visualisation", true),
				new TierFeature("Form metrics", true),
				// new TierFeature("Two Factor Authentication", true),
				new TierFeature("Manual exports in all formats", true),
				new TierFeature("Custom integration", true),
				new TierFeature("AI Access", true),
				new TierFeature("Scheduled exports", true),
			],
			accent: false,
			buttonClass: "btn-outline-info",
		},
		// {
		// 	tierLevel: SubscriptionTier.ENTERPRISE,
		// 	iconColour: "text-danger",
		// 	title: "Enterprise",
		// 	sub: "When you need MORE",
		// 	pricePrefix: "",
		// 	price: "",
		// 	priceSuffix: "",
		// 	features: [
		// 		new TierFeature("Unlimited submissions/mo", true),
		// 		new TierFeature("Simple integration", true),
		// 		new TierFeature("Unlimited forms per site", true),
		// 		new TierFeature("Data visualisation", true),
		// 		new TierFeature("Form metrics", true),
		//		new TierFeature("Two Factor Authentication", true),
		// 		new TierFeature("Manual exports in all formats", true),
		// 		new TierFeature("Scheduled exports in all formats", true),
		// 		new TierFeature("Custom integration", true),
		// 	],
		// 	accent: false,
		// },
	];

	const renderTierFeature = (feature: TierFeature) => {
		return (
			<>
				{feature.active ? <FaCheckCircle className="text-success me-2" /> : <FaTimesCircle className="text-secondary me-2" />}
				{feature.active ? (
					<span>{feature.text}</span>
				) : (
					<span className="text-muted">
						<s>{feature.text}</s>
					</span>
				)}
			</>
		);
	};

	const renderTier = (tier: Tier) => {
		return (
			<>
				<div className={`card h-100 py-3 hover-shadow ${tier.accent ? "bg-dark text-light" : "bg-light"}`}>
					<div className="card-body d-flex align-items-center justify-content-between">
						<div className="me-2">
							{tier.title === "Free" ? (
								<FaCookieBite className={`h1 my-0 ${tier.iconColour}`} />
							) : (
								<FaRegGem className={`h1 my-0 ${tier.iconColour}`} />
							)}
						</div>
						<div>
							<div className="card-title h4 my-0">{tier.title}</div>
							<h6 className={`card-subtitle my-0 ${tier.accent ? null : "text-muted"}`}>
								<small>{tier.sub}</small>
							</h6>
						</div>
						<div className="flex-fill"></div>
						{showPrice ? (
							<div>
								<p>
									<strong className="h3">
										{tier.pricePrefix}
										<span className="number">{tier.price}</span>
									</strong>
									<small>{tier.priceSuffix}</small>
								</p>
							</div>
						) : (
							<></>
						)}
					</div>
					<div className="mx-3 border-top"></div>
					<div className="card-body">
						<div>
							{tier.features.map((f, i) => {
								return (
									<li key={`${tier.title}_feat_${i}`} className="d-flex py-1 align-items-center">
										{renderTierFeature(f)}
									</li>
								);
							})}
						</div>
					</div>

					{showButtons ? (
						<div className="card-body pb-0">
							<Link to="/auth/register">
								<button className={`btn btn-lg container-fluid ${tier.buttonClass}`}>Choose Plan</button>
							</Link>
						</div>
					) : null}
				</div>
			</>
		);
	};

	return (
		<div className={`text-dark ${customClass}`} id="pricing">
			<div className="page-width">
				{showTitle ? (
					<>
						<div className="container-fluid m-auto">
							<div className="w-100 px-3 m-auto mb-5">
								<p className="h1 text-center">Pricing Plans</p>
								<p className="text-center text-muted">Cost-Effective, Full Service. High Security</p>
							</div>
						</div>
					</>
				) : null}

				<div className={`container-fluid m-auto row ${activeTier !== null && activeTier !== undefined ? "p-0" : null}`}>
					{tiers.map((t, i) => {
						var canShow = true;
						var singleItem = false;
						if (activeTier !== null && activeTier !== undefined) {
							singleItem = true;
							if (activeTier !== t.tierLevel) canShow = false;
						}

						if (!canShow) return null;
						return (
							<div key={i} className={`${singleItem ? "w-100 p-0" : "col-lg-3"} mt-2`}>
								{renderTier(t)}
							</div>
						);
					})}
				</div>

				{showTrialText && (
					<div className={`container-fluid m-auto mt-4 row ${activeTier !== null && activeTier !== undefined ? "p-0" : null}`}>
						<p className="mb-0 text-center text-muted">Get a free 14 day trial when you register your first paid plan.</p>
					</div>
				)}

				{/* TODO: Add at a later date, fixed subs for now */}
				<div className={`d-none container-fluid m-auto mt-4 row ${activeTier !== null && activeTier !== undefined ? "p-0" : null}`}>
					<div className={`col-lg-12 ${activeTier !== null && activeTier !== undefined ? "p-0" : null}`}>
						<div className="alert alert-secondary" role={"alert"}>
							Need something <strong>more</strong>? Contact us{" "}
							<Link to="/">
								<span className="btn-link text-primary">here</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
