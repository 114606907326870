import React, { useCallback, useState } from "react";
import FileSaver from "file-saver";
import ExportDataRequest from "../../models/requests/ExportDataRequest";
import FormService from "../../services/form/form-service";
import ModalService from "../../services/ui/modal-service";
import "./export-data-modal.scss";
import { FaExclamation, FaTimes } from "react-icons/fa";
import ToastService from "../../services/ui/toast-service";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
export default function ExportDataModal({ formAPIKey, modalId }: { formAPIKey: string; modalId: string }) {
	const [startDate, setStartDate] = useState<Date>(new Date(Date.now()));
	const [endDate, setEndDate] = useState<Date>(new Date(Date.now()));

	const exportData = useCallback(() => {
		FormService.exportData(formAPIKey, new ExportDataRequest().Build(startDate, endDate), {
			success(_) {
				FileSaver.saveAs(new Blob([..._], { type: "text/plain,charset=utf8" }), `${formAPIKey}_file.csv`);
				ModalService.CloseModal(modalId);
			},
			error(err) {
				ToastService.OpenToast(
					"export_failed",
					"Export Failed",
					"Export failed to complete, if this continues please contact support",
					FaExclamation,
					new ToastConfig({
						iconColour: "danger",
						headerColour: "danger",
						borderColour: "danger",
						autoHide: false,
					})
				);
			},
		});
	}, [startDate, endDate, modalId, formAPIKey]);

	const quickSelect = useCallback((option: "last_7" | "last_14" | "last_month") => {
		const endDate = new Date(Date.now());
		const startDate = new Date(endDate);

		switch (option) {
			case "last_7":
				startDate.setDate(endDate.getDate() - 7);
				break;

			case "last_14":
				startDate.setDate(endDate.getDate() - 14);
				break;

			case "last_month":
				// sets start of last month
				startDate.setDate(1);
				startDate.setHours(-1);
				startDate.setDate(1);

				// set end of last month
				endDate.setDate(1);
				endDate.setHours(-1);
				break;
		}

		setStartDate(startDate);
		setEndDate(endDate);
	}, []);

	return (
		<div className="bg-white p-3 rounded mb-2">
			<div>
				<div className="d-flex justify-content-between align-items-center">
					<h3 className="mb-1">Export Data</h3>
					<div
						className="d-block mb-0 h4 interact"
						onClick={() => {
							ModalService.CloseModal(modalId);
						}}
					>
						<FaTimes />
					</div>
				</div>

				<div>
					<p className="mb-2 text-muted">Select your export date range.</p>

					{/* TODO: Update to date-range-picker component */}
					<p className="mb-0 mt-2 pt-2 border-top">Quick Select</p>
					<div className="mt-1 d-flex align-items-center justify-content-stretch gap-2 flex-wrap mb-2">
						<button
							className="flex-fill-even btn btn-outline-primary"
							onClick={() => {
								quickSelect("last_7");
							}}
						>
							Last 7 Days
						</button>
						<button
							className="flex-fill-even btn btn-outline-primary"
							onClick={() => {
								quickSelect("last_month");
							}}
						>
							Last Full Month
						</button>
					</div>

					<div className="d-flex align-items-center justify-content-stretch gap-2 flex-wrap">
						<div className="flex-fill">
							<label htmlFor="startDate" className="form-label">
								Start
							</label>
							<input
								type="date"
								id="startDate"
								className="form-control"
								value={startDate.toISOString().split("T")[0]}
								onChange={(e) => {
									setStartDate(new Date(new Date(e.target.value).toISOString().split("T")[0]));
								}}
							/>
						</div>
						<div className="flex-fill">
							<label htmlFor="endDate" className="form-label">
								End
							</label>
							<input
								type="date"
								id="endDate"
								className="form-control"
								value={endDate.toISOString().split("T")[0]}
								onChange={(e) => {
									setEndDate(new Date(new Date(e.target.value).toISOString().split("T")[0]));
								}}
							/>
						</div>
					</div>
					<p className="mt-2 mb-2 text-muted text-center">Export size is limited to 10,000 rows.</p>
				</div>
				<div className="mt-1">
					<button className="btn btn-primary w-100" onClick={exportData}>
						Export Now
					</button>
					<p className="mb-0 mt-2 text-muted text-center">Upgrade to Premium for access to our advanced exports.</p>
				</div>
			</div>
		</div>
	);
}
