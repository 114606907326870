import React, { useCallback, useEffect, useState } from "react";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import PricingBoard from "../../components/pricing-board/pricing-board";
import UserSite from "../../models/data/user-site";
import SiteService from "../../services/session/site-service";
import "./dashboard-sites-page.scss";

export default function DashboardSitesPage() {
	// Existing Sites
	// Add new Sites
	// Pricing board

	const [currentSites, setCurrentSites] = useState<UserSite[]>([]);

	const fetchCurrentSites = useCallback(() => {
		SiteService.getUserSites((_: UserSite[]) => {
			setCurrentSites(_);
		});
	}, []);

	useEffect(() => {
		fetchCurrentSites();
	}, [fetchCurrentSites]);

	const renderExistingSites = () => {
		if (currentSites === null || currentSites.length === 0) return null;

		return currentSites.map((site: UserSite, i: number) => {
			return (
				<div key={i} className="bg-light rounded p-2 my-2 d-block d-sm-flex justify-content-between align-items-center">
					<span className="h5 m-0">{site.name}</span>
					<span className=""></span>
					<div className="flex-fill"></div>
					{site.active ? (
						<div className="d-flex align-items-center">
							<FaRegCheckCircle className="h3 m-0 text-success" />
							<span className="h5 m-0 ms-2">Active</span>
						</div>
					) : (
						<div className="d-flex align-items-center">
							<FaRegTimesCircle className="h3 m-0 text-danger" />
							<span className="h5 m-0 ms-2">Inactive</span>
						</div>
					)}
				</div>
			);
		});
	};

	return (
		<div>
			{/* EXISTING SITES */}
			<div className="bg-white p-2 rounded mb-2">
				<h3 className="mb-3">Existing Sites</h3>
				{renderExistingSites()}
			</div>

			{/* PRICING BOARD */}
			<div className="bg-white p-2 rounded mb-2">
				<PricingBoard />
			</div>
		</div>
	);
}
