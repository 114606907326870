import React, { useEffect, useState } from "react";
import { FaExclamation, FaRegCopyright } from "react-icons/fa";
import ContactFormComponent from "../../components/contact-form-component/contact-form-component";
import DeactivateAccountComponent from "../../components/deactivate-account-component/deactivate-account-component";
import ResetPassword from "../../components/reset-password/reset-password";
import UpdatePaymentDetails from "../../components/update-payment-details/update-payment-details";
import { environment } from "../../environment/environment";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import AccountDetailResponse from "../../models/responses/AccountDetailResponse";
import UserService from "../../services/session/user-service";
import ToastService from "../../services/ui/toast-service";
import "./account-settings-page.scss";
import UserSession from "../../models/data/user-session";

export default function AccountSettingsPage() {
	const [accountDetail, setAccountDetail] = useState<AccountDetailResponse>(null);
	const [sessionDetail] = useState<UserSession>(UserService.getCurrentSession());

	useEffect(() => {
		UserService.getDetails({
			success(_) {
				setAccountDetail(_);
			},
			error(err) {
				ToastService.OpenToast(
					"account_fetch_error",
					"Failed to get account detail",
					"Failed to retrieve account details",
					FaExclamation,
					new ToastConfig({
						iconColour: "danger",
						headerColour: "danger",
					})
				);
			},
		});
	}, []);

	return (
		<>
			{sessionDetail === undefined || sessionDetail === null || accountDetail === null ? (
				<></>
			) : (
				<>
					<div className="p-3">
						<div className="col col-md-6 mx-auto">
							<div className="card">
								<div className="card-body">
									<h5 className="card-title text-center">Account Settings</h5>
									<div>
										<div className="accordion" id="accountAccordion">
											{/* Account item */}
											{accountDetail != null && (
												<div className="accordion-item">
													<h2 className="accordion-header" id="flush-account-detail-heading">
														<button
															className="accordion-button collapsed"
															type="button"
															data-bs-toggle="collapse"
															data-bs-target="#flush-account-detail"
															aria-expanded="false"
															aria-controls="flush-account-detail"
														>
															Account Detail
														</button>
													</h2>
													<div
														id="flush-account-detail"
														className="accordion-collapse collapse"
														aria-labelledby="flush-account-detail-heading"
														data-bs-parent="#accountAccordion"
													>
														<div className="p-2">
															<div className="mb-3">
																<label className="form-label mb-0">Full Name</label>
																<input
																	type="text"
																	className="form-control"
																	value={accountDetail.fullName}
																	disabled
																	readOnly
																/>
															</div>
															<div className="mb-3">
																<label className="form-label mb-0">Email address</label>
																<input
																	type="email"
																	className="form-control"
																	value={accountDetail.email}
																	disabled
																	readOnly
																/>
															</div>
														</div>
													</div>
												</div>
											)}
											{/* Account item end */}

											{/* Account item */}
											<div className="accordion-item">
												<h2 className="accordion-header" id="flush-reset-password-heading">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#flush-reset-password"
														aria-expanded="false"
														aria-controls="flush-reset-password"
													>
														Reset Password
													</button>
												</h2>
												<div
													id="flush-reset-password"
													className="accordion-collapse collapse"
													aria-labelledby="flush-reset-password-heading"
													data-bs-parent="#accountAccordion"
												>
													<ResetPassword />
												</div>
											</div>
											{/* Account item end */}

											{/* Account item */}
											{sessionDetail.appSumoUser ? (
												<></>
											) : (
												<div className="accordion-item">
													<h2 className="accordion-header" id="flush-payment-details-heading">
														<button
															className="accordion-button collapsed"
															type="button"
															data-bs-toggle="collapse"
															data-bs-target="#flush-payment-details"
															aria-expanded="false"
															aria-controls="flush-payment-details"
														>
															Update Payment Details
														</button>
													</h2>
													<div
														id="flush-payment-details"
														className="accordion-collapse collapse"
														aria-labelledby="flush-payment-details-heading"
														data-bs-parent="#accountAccordion"
													>
														<UpdatePaymentDetails />
													</div>
												</div>
											)}
											{/* Account item end */}

											{/* Account item */}
											<div className="accordion-item">
												<h2 className="accordion-header" id="flush-contact-heading">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#flush-contact"
														aria-expanded="false"
														aria-controls="flush-contact"
													>
														Contact Us
													</button>
												</h2>
												<div
													id="flush-contact"
													className="accordion-collapse collapse"
													aria-labelledby="flush-contact-heading"
													data-bs-parent="#accountAccordion"
												>
													<ContactFormComponent />
												</div>
											</div>
											{/* Account item end */}

											{/* Account item */}
											<div className="accordion-item">
												<h2 className="accordion-header" id="flush-deactivate-account-heading">
													<button
														className="accordion-button collapsed text-danger"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#flush-deactivate-account"
														aria-expanded="false"
														aria-controls="flush-deactivate-account"
													>
														Deactivate Account
													</button>
												</h2>
												<div
													id="flush-deactivate-account"
													className="accordion-collapse collapse"
													aria-labelledby="flush-deactivate-account-heading"
													data-bs-parent="#accountAccordion"
												>
													<DeactivateAccountComponent />
												</div>
											</div>
											{/* Account item end */}
										</div>
									</div>

									<div>
										{/* Critical Items */}
										{/* <button className="btn btn-outline-danger w-100">Delete Account</button> */}
									</div>

									<div className="mt-3">
										<p className="text-muted text-center mb-0 small">
											<FaRegCopyright className="me-1 mb-0" />
											<span className="mb-0">MyDataPro</span>
											<span className="ms-1 mb-0">• Version: {environment.version}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
