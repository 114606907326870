import React, { useCallback, useEffect, useState } from "react";
import { FaCheck, FaCode, FaExclamation, FaMagic, FaPlus, FaClipboard, FaTrash } from "react-icons/fa";
import LoadingComponent from "../../components/loading-component/loading-component";
import UserSite from "../../models/data/user-site";
import UserSiteForm from "../../models/data/user-site-form";
import { IErrorResp } from "../../models/responses/IErrorResp";
import FormService from "../../services/form/form-service";
import SiteService from "../../services/session/site-service";
import ModalService from "../../services/ui/modal-service";
import "./site-configuration-page.scss";
import FormConfigModal from "../../components/form-config-modal/form-config-modal";
import { useNavigate } from "react-router-dom";
import ToastService from "../../services/ui/toast-service";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import ConfirmationModal from "../../components/confirmation-modal/confirmation-modal";
import PreviewModal from "../../components/preview-modal/preview-modal";
import ModalContainer from "../../components/modal-container/modal-container";
import IntegrationGuidePage from "../integration-guide-page/integration-guide-page";

export default function SiteConfigurationPage() {
	const [currentSite, updateCurrentSite] = useState<UserSite>(SiteService.getCurrentSite());
	const [siteForms, setSiteForms] = useState<UserSiteForm[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);
	const navigate = useNavigate();

	const GetForms = useCallback(() => {
		SiteService.getSiteForms((forms: UserSiteForm[]) => {
			if (forms === null) {
				setSiteForms([]);
				setLoaded(true);
				return;
			}
			setSiteForms([...forms]);
			setLoaded(true);
		});
	}, []);

	const DeleteForm = useCallback(
		(form: UserSiteForm) => {
			ModalService.OpenModal(
				"delete_confirmation",
				<ConfirmationModal
					modalId="delete_confirmation"
					options={{
						header: "Confirm deletion",
						body: `Are you sure you want to delete '${form.name}'? This action is irreversible`,
						declineText: "Cancel",
						confirmText: "Delete",
					}}
					callback={(_: boolean) => {
						if (_) {
							FormService.deleteForm(
								currentSite,
								form.apiKey,
								(forms: UserSiteForm[]) => {
									setSiteForms(forms);
									ToastService.OpenToast(
										"form_deleted",
										"Form Deleted!",
										"This form has been deleted",
										FaCheck,
										new ToastConfig({ autoHide: true, iconColour: "success", headerColour: "success", borderColour: "success" })
									);
								},
								(error: IErrorResp) => {
									ToastService.OpenToast(
										"failed_form_deleted",
										"Form Failed to Delete",
										"If this continues please contact support",
										FaExclamation,
										new ToastConfig({ autoHide: true, iconColour: "danger", headerColour: "danger", borderColour: "danger" })
									);
								}
							);
						} else {
							ToastService.OpenToast(
								"no_delete",
								"Delete Cancelled",
								"Form has not been deleted",
								FaExclamation,
								new ToastConfig({
									autoHide: true,
									borderColour: "warning",
									iconColour: "warning",
								})
							);
						}
					}}
				/>
			);
		},
		[currentSite]
	);

	const AddNewForm = useCallback(() => {
		SiteService.addSiteForm(
			(forms: UserSiteForm[]) => {
				setSiteForms(forms);
				if (forms === undefined || forms.length === 0) {
					ToastService.OpenToast(
						"failed_add_form",
						"Failed to create form",
						"If this continues please contact support",
						FaExclamation,
						new ToastConfig({ autoHide: true, iconColour: "danger", headerColour: "danger", borderColour: "danger" })
					);
					return;
				}

				ModalService.OpenModal(
					"form_edit_modal",
					<FormConfigModal
						modalId="form_edit_modal"
						form={forms[forms.length - 1]}
						deleteCallback={(_: UserSiteForm) => {
							DeleteForm(_);
						}}
					/>
				);
			},
			(error: IErrorResp) => {
				console.log(error);
				ToastService.OpenToast(
					"failed_add_form",
					"Failed to create form",
					error && error.error ? error.error : "If this continues please contact support",
					FaExclamation,
					new ToastConfig({ autoHide: false, iconColour: "danger", headerColour: "danger", borderColour: "danger" })
				);
			}
		);
	}, [DeleteForm]);

	const EditForm = useCallback(
		(form: UserSiteForm) => {
			const editModal = ModalService.OpenModal(
				"form_edit_modal",
				<FormConfigModal
					modalId="form_edit_modal"
					form={form}
					deleteCallback={(_: UserSiteForm) => {
						DeleteForm(_);
					}}
				/>
			);

			if (editModal === null) return;

			const onCloseSub = editModal.onClose.subscribe(() => {
				GetForms();
				editModal.onClose.unsubscribe(onCloseSub);
			});
		},
		[DeleteForm, GetForms]
	);

	const ViewData = useCallback(
		(form: UserSiteForm) => {
			navigate(`/dashboard/${form.id}`);
		},
		[navigate]
	);

	const PreviewForm = useCallback((form: UserSiteForm) => {
		const previewModal = ModalService.OpenModal("preview_form_modal", <PreviewModal form={form} />);
	}, []);

	const CopyAPIKey = useCallback((form: UserSiteForm) => {
		navigator.clipboard.writeText(form.apiKey);
		ToastService.OpenToast(
			"copied_api_key",
			"API key copied!",
			"This form API key is now in your clipboard",
			FaCheck,
			new ToastConfig({ autoHide: true, iconColour: "success", headerColour: "success", borderColour: "success" })
		);
	}, []);

	useEffect(() => {
		GetForms();

		var siteChangeSub = SiteService.siteUpdatedEvent.subscribe((_: UserSite) => {
			updateCurrentSite(_);
			GetForms();
		});

		return () => {
			SiteService.siteUpdatedEvent.unsubscribe(siteChangeSub);
		};
	}, [GetForms]);

	// RENDER METHODS
	const renderSiteForms = () => {
		return siteForms.map((sf: UserSiteForm, i: number) => {
			return (
				<div
					key={i}
					style={{ minWidth: "250px", maxWidth: "300px" }}
					className={`flex-fill-even border rounded px-4 py-3 text-secondary ${sf.active ? "border-secondary" : "border-danger"}`}
				>
					<div className="h-100 text-start">
						<div className="mb-2 px-1">
							<div className="d-flex align-items-center justify-content-between mb-2">
								<p className="h5 mb-0">{sf.name}</p>
								<div className="flex-fill"></div>
								<div className="me-2">
									<FaTrash className="fs-6 mb-0 text-danger d-block interact" onClick={() => DeleteForm(sf)} />
								</div>
								<div>
									<FaClipboard className="fs-6 mb-0 d-block interact" onClick={() => CopyAPIKey(sf)} />
								</div>
							</div>
						</div>
						<div className="mb-3">
							<p className="mb-0">Use the link button above to get your forms API key.</p>
						</div>
						<div className="flex-fill"></div>
						<div>
							<button className="btn btn-outline-primary w-100" onClick={() => ViewData(sf)}>
								View Submissions
							</button>
							<button className="btn btn-primary mt-2 w-100" onClick={() => EditForm(sf)}>
								Edit Form Details
							</button>
							<button className="btn btn-small btn-outline-success mt-2 w-100" onClick={() => PreviewForm(sf)}>
								Preview
							</button>
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<div className="p-3">
			<div className="mb-4">
				<div className="d-flex">
					<div className="border-1 border-secondary border-bottom">
						<p className="h4 mb-0 text-secondary pe-2">Get Started</p>
					</div>
					<div className="flex-fill border-1 border-secondary border-bottom"></div>
				</div>
				<div className="d-flex flex-wrap gap-2 py-2">
					{/*  */}
					<button
						style={{ minWidth: "250px", maxWidth: "300px" }}
						className="flex-fill-even btn btn-primary rounded p-4 ratio ratio-4x3"
						onClick={() => AddNewForm()}
					>
						<div className="d-flex flex-column align-items-center justify-content-center">
							<div>
								<FaPlus className="h5 m-auto mb-2 d-block" />
							</div>
							<h5 className="mb-0">Create New Form</h5>
						</div>
					</button>
					{/*  */}

					{/*  */}
					<button
						style={{ minWidth: "250px", maxWidth: "300px" }}
						className="flex-fill-even btn btn-outline-secondary rounded p-4"
						onClick={() => {
							const modal = ModalService.OpenModal(
								"integration_page",
								<ModalContainer
									modalId="integration_page"
									header="Integration Guide"
									Children={<IntegrationGuidePage />}
								></ModalContainer>
							);
							// navigate("/dashboard/integration");
						}}
					>
						<div className="h-100 text-start">
							<div className="mb-2">
								<div className="d-flex align-items-center justify-content-between">
									<FaCode className="h4 mb-2 d-block" />
								</div>
								<p className="mb-0 fs-5">Integration Guide</p>
							</div>
							<p>Learn how to get your forms embedded in your site.</p>
						</div>
					</button>
					{/*  */}

					{/*  */}
					<button
						style={{ minWidth: "250px", maxWidth: "300px" }}
						className="flex-fill-even btn btn-outline-secondary rounded p-4 disabled"
					>
						<div className="h-100 text-start">
							<div className="mb-2">
								<div className="d-flex align-items-center justify-content-between">
									<FaMagic className="h4 mb-2 d-block" />
									<span className="rounded-pill bg-success text-white px-2 py-1 fs-6 fw-light">coming soon</span>
								</div>
								<p className="mb-0 fs-5">Visual Customisation</p>
							</div>
							<p>Customise the appearance of your form with our style editor.</p>
						</div>
					</button>
					{/*  */}

					<div style={{ minWidth: "250px", maxWidth: "300px" }} className="flex-fill-even p-4"></div>

					{/*			

					
					<button style={{ minWidth: "250px" }} className="flex-fill-even btn btn-outline-secondary rounded p-4">
						<div className="d-flex flex-column align-items-center justify-content-center">
							<div>
								<FaLock className="h5 m-auto mb-2 d-block" />
							</div>
							<h5 className="mb-0">Something else</h5>
						</div>
					</button>
					*/}
				</div>
			</div>

			<div>
				<div className="d-flex">
					<div className="border-1 border-secondary border-bottom">
						<p className="h4 mb-0 text-secondary pe-2">Forms</p>
					</div>
					<div className="flex-fill border-1 border-secondary border-bottom"></div>
				</div>
				{siteForms === null || siteForms.length === 0 ? (
					<div className="my-3">
						<p className="text-center text-muted h5">You don't have any forms yet.</p>
					</div>
				) : (
					<div className="d-flex justify-content-between gap-2 py-2 flex-wrap">
						{loaded ? (
							<>
								{renderSiteForms()}
								<div style={{ minWidth: "250px", maxWidth: "300px" }} className={`flex-fill-even px-4 py-3`}></div>
								<div style={{ minWidth: "250px", maxWidth: "300px" }} className={`flex-fill-even px-4 py-3`}></div>
								<div style={{ minWidth: "250px", maxWidth: "300px" }} className={`flex-fill-even px-4 py-3`}></div>
								<div style={{ minWidth: "250px", maxWidth: "300px" }} className={`flex-fill-even px-4 py-3`}></div>
								<div style={{ minWidth: "250px", maxWidth: "300px" }} className={`flex-fill-even px-4 py-3`}></div>
								<div style={{ minWidth: "250px", maxWidth: "300px" }} className={`flex-fill-even px-4 py-3`}></div>
							</>
						) : (
							<LoadingComponent />
						)}
					</div>
				)}
			</div>
		</div>
	);
}
