// SERVICE METHODS

import { HTTP_CONSTANTS } from "../../constants/http-constants";
import { RefererCode } from "../../models/data/referer-code";
import HttpService, { HttpError } from "../http/http-service";

function _validate(callback: (status: boolean) => void): void {
	HttpService.get<void>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/admin/validate`,
		(_: void) => {
			callback(true);
		},
		(error: HttpError) => {
			callback(false);
		}
	);
}

function _getCodes(referer: string, redeemed: boolean, callback: (codes: RefererCode[]) => void): void {
	HttpService.get<RefererCode[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/admin/referer/${referer}/codes/${redeemed ? "redeemed" : "un-redeemed"}`,
		(_: RefererCode[]) => {
			callback(_);
		},
		(error: HttpError) => {
			callback([]);
		}
	);
}

function _revokeCode(codeId: string, callback: () => void): void {
	HttpService.delete<void>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/admin/referer/${codeId}/revoke`,
		() => {
			callback();
		},
		(error: HttpError) => {
			callback();
		}
	);
}

function _addCodes(req: { refererName: string; codeCount: number }, callback: (_: any) => void): void {
	HttpService.post<{ refererName: string; codeCount: number }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/admin/referer/codes`,
		req,
		(_: RefererCode[]) => {
			callback(_);
		},
		(error: HttpError) => {
			callback([]);
		}
	);
}

// EXPORTS

const AdminService = {
	Validate: _validate,
	GetCodes: _getCodes,
	AddCodes: _addCodes,
	RevokeCode: _revokeCode,
};

export default AdminService;
