import React, { useCallback, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import UserSite from "../../models/data/user-site";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import PaymentService from "../../services/payment/payment-service";
import SiteService from "../../services/session/site-service";
import ToastService from "../../services/ui/toast-service";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import SiteSelectDropdownComponent from "../site-select-dropdown-component/site-select-dropdown-component";
import "./update-payment-details.scss";
export default function UpdatePaymentDetails() {
	const [currentSite, setCurrentSite] = useState<UserSite>(SiteService.getCurrentSite());

	const updatePaymentMethod = useCallback(() => {
		LoadingOverlay.Show();
		PaymentService.UpdatePaymentMethod(currentSite, {
			success(_) {
				LoadingOverlay.Hide();
				window.open(_);
			},
			error(err) {
				LoadingOverlay.Hide();
				ToastService.OpenToast(
					"fail_update_payment",
					"Failed to update payment",
					"If this site isn't yet active you cant update the payment method. If you believe this is an error, please contact support.",
					FaTimes,
					new ToastConfig({
						iconColour: "danger",
						headerColour: "danger",
						borderColour: "danger",
					})
				);
			},
		});
	}, [currentSite]);

	useEffect(() => {
		const siteUpdateSub = SiteService.siteUpdatedEvent.subscribe((site: UserSite) => {
			setCurrentSite(site);
		});

		return () => {
			SiteService.siteUpdatedEvent.unsubscribe(siteUpdateSub);
		};
	}, []);

	return (
		<div className="p-2">
			<div className="d-block w-100">
				<SiteSelectDropdownComponent />
				<div className="mt-2">
					<button className="btn btn-primary w-100" onClick={updatePaymentMethod}>
						Update Payment Method
					</button>
				</div>
			</div>
		</div>
	);
}
