import React, { useEffect, useState } from "react";
import { FormMetrics } from "../../models/data/form-metrics";
import UserSiteForm from "../../models/data/user-site-form";
import FormService from "../../services/form/form-service";
import { FaCertificate, FaCookieBite, FaRegEye } from "react-icons/fa";
import "./form-metrics-component.scss";
import MetricChartComponent from "../metric-chart-component/metric-chart-component";
import UserSite from "../../models/data/user-site";
import { SubscriptionTier } from "../../models/data/subscription-tier";
import { Link } from "react-router-dom";

export default function FormMetricsComponent({ site, form }: { site: UserSite; form: UserSiteForm }) {
	const [formMetrics, setFormMetrics] = useState<FormMetrics>(null);

	useEffect(() => {
		FormService.getFormMetrics(form.apiKey, {
			success(_) {
				setFormMetrics(_);
			},
			error(err) {},
		});
	}, [form]);

	return (
		<div className="mb-5">
			<div className="d-flex mb-2">
				<div className="border-1 border-secondary border-bottom">
					<p className="h4 mb-0 text-secondary pe-2">{form.name} Metrics</p>
				</div>
				<div className="flex-fill border-1 border-secondary border-bottom"></div>
			</div>
			<div className="position-relative">
				<div
					className={`d-flex align-items-center justify-content-start flex-wrap gap-2 ${
						site.subscriptionTier === SubscriptionTier.FREE ? "opacity-2" : null
					}`}
				>
					{formMetrics && (
						<MetricChartComponent
							enabled={site.subscriptionTier !== SubscriptionTier.FREE}
							metric={formMetrics.views}
							title={"Form Views"}
							description={""}
							label={"Views"}
							icon={<FaRegEye />}
						/>
					)}
					{formMetrics && (
						<MetricChartComponent
							enabled={site.subscriptionTier !== SubscriptionTier.FREE}
							metric={formMetrics.interactions}
							title={"Interactions"}
							description={"Interacted with the form"}
							label={"Interactions"}
							icon={<FaCookieBite />}
						/>
					)}
					{formMetrics && (
						<MetricChartComponent
							enabled={site.subscriptionTier !== SubscriptionTier.FREE}
							metric={formMetrics.submissions}
							title={"Submissions"}
							description={"Submitted a"}
							label={"Submissions"}
							icon={<FaCertificate />}
						/>
					)}
				</div>
				{site.subscriptionTier === SubscriptionTier.FREE ? (
					<div className="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center">
						<div className="bg-dark opacity-6 position-absolute top-0 start-0 end-0 bottom-0"></div>
						<Link to="/dashboard/settings" className="p-2" style={{ zIndex: 1 }}>
							<h4 className="text-center display-5 text-white mb-0">Upgrade to make the most of form metrics</h4>
						</Link>
					</div>
				) : null}
			</div>
		</div>
	);
}
