import React, { useCallback, useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { IconType } from "react-icons/lib";
import IMDPToast, { ToastConfig } from "../../models/interfaces/IMDPToast";
import EventEmitter from "../event/event-emitter";

function ToastServiceComponent() {
	const [toasts, setToasts] = useState<IMDPToast[]>([]);

	useEffect(() => {
		setToasts([]);

		var toastSub = ToastService.ToastChangeEvent.subscribe((_: IMDPToast[]) => {
			setToasts([..._]);
		});

		return () => {
			ToastService.ToastChangeEvent.unsubscribe(toastSub);
		};
	}, []);

	const TOAST_HTML = (key: number, id: string, toast: IMDPToast) => {
		var d = new Date();
		return (
			<Toast
				key={key}
				id={id}
				className={`border border-${toast.config.borderColour}`}
				show={toast.active}
				onClose={() => toast.close()}
				delay={toast.config.duration}
				autohide={toast.config.autoHide}
			>
				<Toast.Header>
					<toast.icon className={`me-2 text-${toast.config.iconColour}`} />
					<strong className={`me-auto text-${toast.config.headerColour}`}>{toast.header}</strong>
					<small>{`${("0" + d.getHours()).slice(-2)}:${d.getMinutes()}:${d.getSeconds()}`}</small>
				</Toast.Header>
				<Toast.Body>{toast.content}</Toast.Body>
			</Toast>
		);
	};

	const TOAST_PARENT = useCallback(() => {
		return (
			<>
				<div id="root_toast_parent">
					<ToastContainer className="p-2 position-fixed" position={"bottom-end"}>
						{toasts.map((m, i) => {
							if (m.active) return TOAST_HTML(i, `toast_${i}`, m);
							else return null;
						})}
					</ToastContainer>
				</div>
			</>
		);
	}, [toasts]);

	return <>{TOAST_PARENT()}</>;
}

// SERVICE STUFF

var TOASTS: IMDPToast[] = [];
var toastChangeEvent: EventEmitter<IMDPToast[]> = new EventEmitter<IMDPToast[]>();

function _OpenToast(id: string, header: string, content: string, icon: IconType, config: ToastConfig = new ToastConfig()): IMDPToast {
	id = `${id}_${TOASTS.length}`;

	var newToast = new IMDPToast(id, TOASTS.length + 1, icon, header, content, config, true, () => {
		// close toast callback
		var existingIndex = TOASTS.findIndex((_) => _.id === newToast.id);
		TOASTS.splice(existingIndex, 1);
		toastChangeEvent.emit(TOASTS);
	});

	var tempToasts = [...TOASTS, newToast];
	TOASTS = tempToasts;

	if (TOASTS.length > 5) {
		TOASTS.splice(0, 5);
	}

	toastChangeEvent.emit(TOASTS);

	return newToast;
}

function _CloseToastById(id: string): void {
	var existingIndex = TOASTS.findIndex((_) => _.id === id);
	TOASTS.splice(existingIndex, 1);
	toastChangeEvent.emit(TOASTS);
}

const ToastService = {
	Component: ToastServiceComponent,
	ToastChangeEvent: toastChangeEvent,
	OpenToast: _OpenToast,
	CloseToast: _CloseToastById,
};

export default ToastService;
