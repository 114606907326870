import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import UserService from "../../services/session/user-service";
import "./forgot-recovery-page.scss";
import logo from "../../assets/images/mdp_logo_black.png";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import StatefullInput from "../../components/statefull-input/statefull-input";
import StatefullLabel from "../../components/statefull-label/statefull-label";
import heroImage from "../../assets/images/HeroImage_1.png";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import { FaCheck, FaExclamation } from "react-icons/fa";
import ToastService from "../../services/ui/toast-service";
import { Buffer } from "buffer";

export default function ForgotRecoveryPage() {
	const [email, updateEmail] = useState("");
	const [password, updatePassword] = useState("");
	const [passwordRepeat, updatePasswordRepeat] = useState("");
	const [recoverRequest, updateRecoverRequest] = useState(null);

	const [searchParams] = useSearchParams(); // from query

	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());
	const navigate = useNavigate();

	const keyDownHandler = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			MakeRecoverRequest();
		}
	};

	useEffect(() => {
		updateRecoverRequest({
			email: email,
			password: Buffer.from(password).toString("base64"),
			passwordRepeat: Buffer.from(passwordRepeat).toString("base64"),
			u: searchParams.get("u"),
			c: searchParams.get("c"),
		});
	}, [email, password, passwordRepeat, searchParams]);

	const MakeRecoverRequest = () => {
		UserService.recoverPassword(recoverRequest, {
			success(_) {
				ToastService.OpenToast("recover_request", "Success", "Your password has been reset", FaCheck, new ToastConfig().success());
				navigate("/auth/login");
			},
			error(err) {
				try {
					errorState.emit(err);
				} catch (exception: any) {
					ToastService.OpenToast(
						"forgot_request_fail",
						"Something went wrong",
						"We couldn't process your request, please try again.",
						FaExclamation,
						new ToastConfig().danger()
					);
				}
			},
		});
	};

	return (
		<div className="pg-100 bg-light d-flex align-items-stretch">
			<div className="bg-light col-12 col-lg-6 d-flex align-items-center justify-content-center">
				<div className="w-75 m-auto">
					<div>
						<Link to={"/"}>
							<img src={logo} height="50" className="d-inline-block align-top mb-5" alt="mdp logo" />
						</Link>
						<p className="h3">Recover Account</p>
						<p className="lead">Let's get your account back up and running.</p>
					</div>

					<form className="mt-4">
						<div className="mb-3">
							<StatefullInput
								stateId="email"
								label="E-mail"
								defaultValue={email}
								inputType="text"
								autocompleteType="email"
								onChangeCallback={(val: string) => updateEmail(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>

						<div className="mb-3">
							<StatefullInput
								stateId="password"
								label="Password"
								defaultValue={password}
								inputType="password"
								autocompleteType="new-password"
								onChangeCallback={(val: string) => updatePassword(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>

						<div className="mb-3">
							<StatefullInput
								stateId="repeatPassword"
								label="Repeat Password"
								defaultValue={passwordRepeat}
								inputType="password"
								autocompleteType="new-password"
								onChangeCallback={(val: string) => updatePasswordRepeat(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>
					</form>

					<StatefullLabel stateId="global" stateChangeEvent={errorState} />

					<div className="border-bottom my-4"></div>

					<div className="d-flex justify-content-between align-items-center">
						<div></div>

						<div className="">
							<button className="btn btn-success" onClick={MakeRecoverRequest}>
								Submit
							</button>
						</div>
					</div>

					<div className="text-center mt-4">
						<span className="me-2">Got your details?</span>
						<Link to="/auth/login">
							<span className="text-success">
								<strong>Log in</strong>
							</span>
						</Link>
					</div>
				</div>
			</div>

			<div className="bg-success col-6 lg-6 d-none d-lg-flex align-items-center justify-content-center">
				<div className="text-light text-center">
					<div className="px-5 mb-4">
						<img className="d-block w-100 m-auto" style={{ maxHeight: "450px", maxWidth: "500px" }} alt="" src={heroImage} />
					</div>
					<p className="h1 m-0">
						<b>Designed for individuals</b>
					</p>
					<p className="h1 mb-3">
						<b>and businesses combined.</b>
					</p>
					<p className="fs-6 mb-0">Track your analytics and grow</p>
					<p className="fs-6">your data remotely</p>
				</div>
			</div>
		</div>
	);
}
