export const environment: {
	version: string;
	production: boolean;
	protocol: string;
	baseAddress: string;
	stripeKey: string;
} = {
	version: "0.0.1",
	production: true,
	protocol: "https://",
	baseAddress: "www.mydatapro.co.uk/api",
	stripeKey: "pk_live_51LnTxxDjy62RQKPFgAF2jwYvZXtfDUzHvsRvRy09tsfOkB0nkMdrOqRnRVmJzIvKxBLzlo3ACXCZAm39U61o9JLY00Nco1k0KO",
};
