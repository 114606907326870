import React from "react";
import { useParams } from "react-router-dom";
import "./integration-page.scss";
import WebsiteForm from "../../components/website-form/website-form";

export default function IntegrationPage() {
	let { APIKEY, hostName } = useParams(); // from url

	return <WebsiteForm APIKEY={APIKEY} hostName={hostName} preview={false} />;
}
