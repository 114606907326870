import { Buffer } from "buffer";

export default class LoginRequest {
	constructor(public email: string, public password: string) {}

	// Prevents mutation
	public encode(): LoginRequest {
		return new LoginRequest(this.email, Buffer.from(this.password).toString("base64"));
	}
}
