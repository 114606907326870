import React, { useCallback, useEffect, useState } from "react";
import { FaListUl, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import UserSite from "../../models/data/user-site";
import UserSiteForm from "../../models/data/user-site-form";
import SiteService from "../../services/session/site-service";
import "./site-form-list-component.scss";

export default function SiteFormListComponent({
	formChangeCallback,
	activeForm,
}: {
	formChangeCallback: (form: UserSiteForm) => void;
	activeForm: UserSiteForm;
}) {
	const [siteForms, setSiteForms] = useState<UserSiteForm[]>([]);

	useEffect(() => {
		fetchSiteForms();

		const subscription = SiteService.siteUpdatedEvent.subscribe((newSite: UserSite) => {
			fetchSiteForms();
		});

		return () => {
			SiteService.siteUpdatedEvent.unsubscribe(subscription);
		};
	}, []);

	const selectForm = useCallback(
		(_form: UserSiteForm) => {
			formChangeCallback(_form);
		},
		[formChangeCallback]
	);

	const fetchSiteForms = () => {
		SiteService.getSiteForms((_: UserSiteForm[]) => {
			setSiteForms(_);
		});
	};

	const noSiteForms = () => {
		return (
			<Link
				to="/dashboard/configuration"
				style={{ minWidth: "150px", maxWidth: "250px" }}
				className={`flex-fill-even btn btn-primary rounded p-4 ratio ratio-21x9`}
			>
				<button className="flex-fill-even btn btn-primary rounded p-4 ratio ratio-21x9">
					<div className="d-flex flex-column align-items-center justify-content-center">
						<div>
							<FaStar className="h5 m-auto mb-2 d-block" />
						</div>
						<h5 className="mb-0">Add your first form!</h5>
					</div>
				</button>
			</Link>
		);
	};

	const renderSiteForms = () => {
		if (siteForms === null || siteForms.length === 0) return noSiteForms();
		return siteForms.map((form: UserSiteForm, i: number) => {
			return (
				<button
					key={i}
					style={{ minWidth: "250px", maxWidth: "500px" }}
					className={`flex-fill-even btn ${
						activeForm !== null && form.id === activeForm.id ? "btn-primary" : "btn-outline-secondary"
					} rounded p-4 ratio ratio-21x9`}
					onClick={() => selectForm(form)}
				>
					<div className="d-flex flex-column align-items-center justify-content-center">
						<div>
							<FaListUl className="h5 m-auto mb-2 d-block" />
						</div>
						<h5 className="mb-0">{form.name}</h5>
					</div>
				</button>
			);
		});
	};

	return (
		<div className="mb-4">
			<div className="d-flex">
				<div className="border-1 border-secondary border-bottom">
					<p className="h4 mb-0 text-secondary pe-2">Forms</p>
				</div>
				<div className="flex-fill border-1 border-secondary border-bottom"></div>
			</div>
			<div className="d-flex flex-wrap gap-x-2 py-2">
				{renderSiteForms()}
				<div style={{ minWidth: "250px", maxWidth: "500px" }} className="flex-fill-even px-4"></div>
				<div style={{ minWidth: "250px", maxWidth: "500px" }} className="flex-fill-even px-4"></div>
				<div style={{ minWidth: "250px", maxWidth: "500px" }} className="flex-fill-even px-4"></div>
				<div style={{ minWidth: "250px", maxWidth: "500px" }} className="flex-fill-even px-4"></div>
				<div style={{ minWidth: "250px", maxWidth: "500px" }} className="flex-fill-even px-4"></div>
			</div>
		</div>
	);
}
