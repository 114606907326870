import React, { useCallback, useEffect, useState } from "react";
import "./admin-dashboard-landing-page.scss";
import { useNavigate } from "react-router-dom";
import AdminService from "../../services/session/admin-service";
import UserService from "../../services/session/user-service";
import { RefererCode } from "../../models/data/referer-code";
import StatefullInput from "../../components/statefull-input/statefull-input";
import EventEmitter from "../../services/event/event-emitter";
import { IErrorResp } from "../../models/responses/IErrorResp";
import SelectableDropdown from "../../components/selectable-dropdown/selectable-dropdown";
import FileSaver from "file-saver";
import { FaTrash } from "react-icons/fa";

export default function AdminDashboardLandingPage() {
	const [usedRefererCodes, updateUsedCodeList] = useState<RefererCode[]>([]);
	const [unusedRefererCodes, updateUnusedCodeList] = useState<RefererCode[]>([]);

	const [newRefererCodeData, updateRefererCodeData] = useState<{ refererName: string; codeCount: number }>({
		refererName: "APPSUMO",
		codeCount: 2,
	});
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	const navigate = useNavigate();

	useEffect(() => {
		if (UserService.getCurrentSession() === null) return;
		if (UserService.getCurrentSession().accountType === 0) navigate("/dashboard");

		AdminService.Validate((_: boolean) => {
			if (!_) {
				navigate("/dashboard");
				return;
			}

			AdminService.GetCodes("appsumo", false, (_) => updateUnusedCodeList(_));
			AdminService.GetCodes("appsumo", true, (_) => updateUsedCodeList(_));
		});
	}, [navigate]);

	const revokeCodeHandler = useCallback((codeId: string) => {
		AdminService.RevokeCode(codeId, () => {
			AdminService.GetCodes("appsumo", false, (_) => updateUnusedCodeList(_));
			AdminService.GetCodes("appsumo", true, (_) => updateUsedCodeList(_));
		});
	}, []);

	const addMoreCodesHandler = useCallback(() => {
		AdminService.AddCodes(newRefererCodeData, (_: any) => {
			FileSaver.saveAs(
				new Blob([..._], { type: "text/plain,charset=utf8" }),
				`${newRefererCodeData.refererName}_${Date.now().toString()}_file.csv`
			);

			AdminService.GetCodes("appsumo", false, (_) => updateUnusedCodeList(_));
			AdminService.GetCodes("appsumo", true, (_) => updateUsedCodeList(_));
		});
	}, [newRefererCodeData]);

	return (
		<>
			<div className="border rounded p-2 bg-white mb-2">
				<h2>Add more codes</h2>
				<div>
					<div className="d-flex align-items-center justify-content-between gap-2">
						<div className="flex-fill">
							<SelectableDropdown
								stateId="codeRefererName"
								label={null}
								options={["APPSUMO"]}
								selectedOption={newRefererCodeData.refererName ? newRefererCodeData.refererName : "APPSUMO"}
								onChange={(val: string) => {
									updateRefererCodeData({ ...newRefererCodeData, refererName: val });
								}}
							/>
						</div>
						<div className="flex-fill">
							<StatefullInput
								stateId="codeRefererCout"
								label={null}
								placeholder="Referer Count"
								defaultValue={newRefererCodeData.codeCount.toString()}
								inputType="number"
								autocompleteType="off"
								onChangeCallback={(val: string) => {
									updateRefererCodeData({ ...newRefererCodeData, codeCount: Number.parseInt(val) });
								}}
								onPressCallback={() => {}}
								stateChangeEvent={errorState}
							/>
						</div>
						<button className="btn btn-primary" onClick={() => addMoreCodesHandler()}>
							Submit
						</button>
					</div>
				</div>
			</div>
			<ul className="list-group mb-2">
				{unusedRefererCodes.map((r: RefererCode, i: number) => {
					return (
						<li key={i} className="list-group-item d-flex justify-content-between align-items-center">
							<span>
								{r.refererName} - {r.code}
							</span>
							<FaTrash className="interact" onClick={() => revokeCodeHandler(r.id)} />
						</li>
					);
				})}
			</ul>

			<ul className="list-group">
				{usedRefererCodes.map((r: RefererCode, i: number) => {
					return (
						<li key={i} className="list-group-item">
							<span>
								{r.refererName} - {r.code} - {r.site.name}
							</span>
						</li>
					);
				})}
			</ul>
		</>
	);
}
