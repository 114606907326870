import React, { useCallback, useEffect, useState } from "react";
import { FaBomb, FaCheck, FaChevronLeft, FaChevronRight, FaCogs, FaDownload, FaExclamation, FaSyncAlt } from "react-icons/fa";
import FormDataRow from "../../models/data/form-data-row";
import UserSite from "../../models/data/user-site";
import UserSiteForm from "../../models/data/user-site-form";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import { IPaginationHeaders, IPaginationResponse, SORTBY } from "../../models/interfaces/IPaginationHeaders";
import FetchDataResponse from "../../models/responses/FetchDataResponse";
import PaginationDataResponse from "../../models/responses/PaginationDataResponse";
import FormService from "../../services/form/form-service";
import { HttpError } from "../../services/http/http-service";
import SiteService from "../../services/session/site-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import ConfirmationModal from "../confirmation-modal/confirmation-modal";
import DataRowComponent from "../data-row-component/data-row-component";
import ExportDataModal from "../export-data-modal/export-data-modal";
import FormMetricsComponent from "../form-metrics-component/form-metrics-component";
import SiteFormListComponent from "../site-form-list-component/site-form-list-component";
import "./data-row-table-component.scss";

// Controls current table data for the active user site - SiteService
// Controls filters + sorting + exports
// Controls presenting rows - DataRowComponent
export default function DataRowTableComponent({ urlFormId }: { urlFormId: string }) {
	const [formId, setFormId] = useState(urlFormId);

	// SITE
	const [currentSite, updateCurrentSite] = useState<UserSite>(SiteService.getCurrentSite());
	const [siteForm, setSiteForm] = useState<UserSiteForm>(null);
	const [loading, setLoading] = useState(false);

	// DATA
	const [siteData, setSiteData] = useState<FetchDataResponse>();
	const [paginationData, setPaginationData] = useState<IPaginationResponse>();
	const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

	const fetchData = useCallback(
		(APIKey: string, pageIndex: number) => {
			var pagination: IPaginationHeaders = {
				page: pageIndex,
				pageSize: 9,
				sortBy: SORTBY.DESC,
				dateRange: null,
			};
			setLoading(true);
			FormService.getCurrentSiteData(currentSite, APIKey, pagination, {
				success(resp: PaginationDataResponse<FetchDataResponse>) {
					setSiteData(resp.data);
					setPaginationData(resp.pagination);
					setTimeout(() => {
						setLoading(false);
					}, 500);
				},
				error(error: HttpError) {
					setLoading(false);
					ToastService.OpenToast(
						"failed_to_load_rows",
						error.error.error,
						error.error.fixes[0]["value"],
						FaCogs,
						new ToastConfig({
							iconColour: "danger",
							headerColour: "danger",
							borderColour: "danger",
							autoHide: true,
						})
					);
				},
			});
		},
		[currentSite]
	);

	const setSelectedForm = useCallback(
		(form: UserSiteForm) => {
			if (form === undefined) return;
			if (siteForm !== null && siteForm.id === form.id) return;
			setSiteData(null);
			setPaginationData(null);
			setCurrentPageIndex(0);
			setSiteForm(form);
			window.history.replaceState(null, null, `/dashboard/${form.id}`);
		},
		[siteForm]
	);

	const OpenExport = useCallback(() => {
		if (siteForm === null) return;
		ModalService.OpenModal("export_data_modal", <ExportDataModal formAPIKey={siteForm.apiKey} modalId={"export_data_modal"} />);
	}, [siteForm]);

	useEffect(() => {
		if (siteForm === null || siteForm === undefined) return;
		if (currentSite === null || currentSite === undefined) return;
		if (currentPageIndex < 0) return;
		fetchData(siteForm.apiKey, currentPageIndex);
	}, [fetchData, currentSite, currentPageIndex, siteForm]);

	const paginationNext = () => {
		setCurrentPageIndex(currentPageIndex + 1);
	};

	const paginationPrev = () => {
		setCurrentPageIndex(currentPageIndex - 1);
	};

	// INIT
	useEffect(() => {
		const subscription = SiteService.siteUpdatedEvent.subscribe((newSite: UserSite) => {
			updateCurrentSite(newSite);

			setSiteData(null);
			setPaginationData(null);
			setCurrentPageIndex(0);
			setSiteForm(null);
		});

		SiteService.getSiteForms((_: UserSiteForm[]) => {
			if (_ === null) return;
			if (formId === null || formId === undefined) {
				setSelectedForm(_[0]);
				return;
			}
			_.forEach((f: UserSiteForm) => {
				if (f.id === formId) {
					setSelectedForm(f);
				}
			});
		});

		return () => {
			SiteService.siteUpdatedEvent.unsubscribe(subscription);
		};
	}, [setSelectedForm, formId]);

	const deleteRowCallback = useCallback(
		(id: number) => {
			FormService.deleteRow(currentSite, siteForm.apiKey, id, {
				success(_) {
					const tempData = siteData;
					siteData.rows = [...tempData.rows.filter((r) => r.id !== id)];
					setSiteData({ ...siteData });

					ToastService.OpenToast(
						"deleted_row",
						"Deleted row",
						"Row has been removed from responses",
						FaCheck,
						new ToastConfig({ autoHide: true }).success()
					);
				},
				error(err) {
					ToastService.OpenToast(
						"failed_to_delete",
						"Failed to delete row",
						"We couldnt delete this at this time, if this continues please contact support",
						FaBomb,
						new ToastConfig().danger()
					);
				},
			});
		},
		[currentSite, siteForm, siteData]
	);

	const confirmDeleteCallback = useCallback(
		(id: number) => {
			ModalService.OpenModal(
				"confirm_row_delete",
				<ConfirmationModal
					modalId="confirm_row_delete"
					options={{
						header: "Confirm deletion",
						body: `Are you sure you want to delete this row? This action is irreversible`,
						declineText: "Cancel",
						confirmText: "Delete",
					}}
					callback={(_: boolean) => {
						if (_) deleteRowCallback(id);
						else
							ToastService.OpenToast(
								"no_delete",
								"Delete Cancelled",
								"This row has not been deleted",
								FaExclamation,
								new ToastConfig({ autoHide: true }).warning()
							);
					}}
				/>
			);
		},
		[deleteRowCallback]
	);

	const drawRows = () => {
		const rows = siteData.rows.map((rd: FormDataRow, index: number) => {
			return (
				<DataRowComponent
					key={index}
					index={index}
					rowData={rd}
					columns={siteData.columns}
					deleteRowCallback={() => confirmDeleteCallback(rd.id)}
				/>
			);
		});
		return rows;
	};

	const drawPagination = () => {
		return paginationData === undefined ? (
			<div></div>
		) : (
			<div className="px-2 py-1 bg-white rounded d-flex justify-content-between align-items-center">
				<span className="text-muted small">
					{paginationData.page * paginationData.pageSize + 1} - {paginationData.page * paginationData.pageSize + siteData.rows.length} of{" "}
					{paginationData.totalCount} records
				</span>
				<div></div>
				<div>
					<button
						className={`btn btn-sm ${paginationData.hasPrev ? "btn-primary" : "btn-outline-light"}`}
						disabled={!paginationData.hasPrev}
						onClick={() => {
							paginationPrev();
						}}
					>
						<FaChevronLeft />
					</button>
					<div className="d-inline-block px-1"></div>
					<button
						className={`btn btn-sm ${paginationData.hasNext ? "btn-primary" : "btn-outline-light"}`}
						disabled={!paginationData.hasNext}
						onClick={() => {
							paginationNext();
						}}
					>
						<FaChevronRight />
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className="">
			<div>
				<SiteFormListComponent
					activeForm={siteForm}
					formChangeCallback={(form: UserSiteForm) => {
						setFormId(form.id);
						setSelectedForm(form);
					}}
				/>
			</div>
			{siteForm && <FormMetricsComponent site={currentSite} form={siteForm} />}
			{siteData && siteForm && (
				<div className="mb-5">
					<div className="d-flex">
						<div className="border-1 border-secondary border-bottom">
							<p className="h4 mb-0 text-secondary pe-2">{siteForm.name} Data</p>
						</div>
						<div className="flex-fill border-1 border-secondary border-bottom"></div>
						<div className="d-flex align-items-center justify-content-center border-1 border-secondary border-bottom">
							<div>
								<FaDownload onClick={() => OpenExport()} className="h4 mb-0 text-primary me-3 interact" />
							</div>
							<FaSyncAlt
								onClick={() => {
									fetchData(siteForm.apiKey, currentPageIndex);
								}}
								className={`h4 mb-0 text-primary me-2 interact ${loading ? "spin" : ""}`}
							/>
						</div>
					</div>
					<div className="py-2">
						{siteData.rows.length > 0 ? (
							<>
								<div>{drawPagination()}</div>
								<div>{drawRows()}</div>
								<div>{drawPagination()}</div>
							</>
						) : (
							<>
								<div className="my-3">
									<p className="text-center text-muted h5">This form currently doesn't have any submissions.</p>
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
