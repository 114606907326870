import { SubscriptionTier } from "../data/subscription-tier";

export default class AddSiteRequest {
	siteName: string;
	subscriptionTier: SubscriptionTier = SubscriptionTier.PREMIUM;

	// META

	description: string;
	tags: string;

	// REFERER

	useAppSumo?: boolean;
	appSumoCode?: string;
}
