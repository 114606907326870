export class SiteDataColumn {
	public valid: boolean = true;

	constructor(
		public name: string,
		public required: boolean = false,
		public type: "text" | "number" | "date" | "dropdown" | "textarea",
		public options?: string[]
	) {}
}
