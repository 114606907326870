import React, { useEffect, useState } from "react";
import EventEmitter from "../../services/event/event-emitter";
import "./loading-overlay.scss";

function LoadingOverlayParent() {
	const [show, setShow] = useState<boolean>(false);

	useEffect(() => {
		const sub = LoadingOverlay.StateChangeEvent.subscribe((_: boolean) => {
			setShow(_);
		});
		return () => {
			LoadingOverlay.StateChangeEvent.unsubscribe(sub);
		};
	}, []);

	const LoadingOverlayComponent = () => {
		return (
			<div
				className="opacity-9 position-absolute start-0 end-0 top-0 bottom-0 bg-dark d-flex justify-content-center align-items-center"
				style={{ zIndex: 1000 }}
			>
				<h2 className="mb-0 display-4 text-white">Loading...</h2>
			</div>
		);
	};

	return <>{show ? LoadingOverlayComponent() : null}</>;
}

var stateChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

function _showOverlay(): void {
	stateChangeEvent.emit(true);
}

function _hideOverlay(): void {
	stateChangeEvent.emit(false);
}

const LoadingOverlay = {
	Component: LoadingOverlayParent,
	StateChangeEvent: stateChangeEvent,
	Show: _showOverlay,
	Hide: _hideOverlay,
};

export default LoadingOverlay;
