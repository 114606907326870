import React, { useCallback, useEffect, useState } from "react";
import "./dashboard-landing-page.scss";
import DataRowTableComponent from "../../components/data-row-table-component/data-row-table-component";
import SiteService from "../../services/session/site-service";
import UserSite from "../../models/data/user-site";
import ModalService from "../../services/ui/modal-service";
import CreateSiteModal from "../../components/create-site-modal/create-site-modal";
import { Link, useParams } from "react-router-dom";
import { FaRegFrown } from "react-icons/fa";

export default function DashboardLandingPage() {
	const [availableSites, setAvailableSites] = useState<UserSite[]>([]);
	const [hasSites, setHasSites] = useState<boolean>(SiteService.getCurrentSite() != null);
	const [currentSite, setCurrentSite] = useState<UserSite>(SiteService.getCurrentSite());
	const [loaded, setLoaded] = useState<boolean>(false);
	let { formId } = useParams(); // from url

	useEffect(() => {
		var subscription = SiteService.siteUpdatedEvent.subscribe((_: UserSite) => {
			setHasSites(SiteService.getCurrentSite() != null);
			setCurrentSite({ ..._ });
		});

		SiteService.getUserSites((userSites: UserSite[]) => {
			setAvailableSites(userSites);
			setLoaded(true);
			if (userSites === null) return;

			const currSite = SiteService.getCurrentSite();
			if (currSite === null) return;
			userSites.forEach((us: UserSite) => {
				if (us.id === currSite.id) setCurrentSite({ ...us });
			});
		});

		var siteListUpdateSub = SiteService.siteListUpdateEvent.subscribe(() => {
			SiteService.getUserSites((userSites: UserSite[]) => setAvailableSites(userSites));
			setLoaded(true);
		});

		return () => {
			SiteService.siteListUpdateEvent.unsubscribe(siteListUpdateSub);
			SiteService.siteUpdatedEvent.unsubscribe(subscription);
		};
	}, []);

	useEffect(() => {
		setHasSites(availableSites !== null && availableSites.length > 0);
	}, [availableSites]);

	const createFirstSite = useCallback(() => {
		ModalService.OpenModal("new_site_modal", <CreateSiteModal modalId="new_site_modal" />);
	}, []);

	const NoSiteError = () => {
		return (
			<div className="h-100 d-flex justify-content-center align-items-center flex-column text-center">
				<FaRegFrown className="d-block h1" />
				<h4>No sites have been configured for this account.</h4>
				<button
					className="btn btn-primary"
					onClick={() => {
						createFirstSite();
					}}
				>
					Get Started
				</button>
			</div>
		);
	};

	const NotActiveError = () => {
		return (
			<div className="h-100 d-flex justify-content-center align-items-center flex-column text-center">
				<FaRegFrown className="d-block h1" />
				<h4>This site is not yet active.</h4>
				<Link to="/dashboard/settings">
					<button className="btn btn-primary">Activate Now</button>
				</Link>
			</div>
		);
	};

	return (
		<div className="h-100">
			{loaded ? (
				<div className="h-100">
					{hasSites ? (
						<>{currentSite && currentSite.active ? <DataRowTableComponent urlFormId={formId} /> : NotActiveError()}</>
					) : (
						NoSiteError()
					)}
				</div>
			) : (
				<div className="h-100 w-100 d-flex justify-content-center align-items-center">
					<div className="text-center">
						<div className="m-auto spinner-border text-primary" role="status" style={{ width: "5rem", height: "5rem" }}>
							<span className="visually-hidden">Loading...</span>
						</div>
						<h4>Loading...</h4>
					</div>
				</div>
			)}
		</div>
	);
}
