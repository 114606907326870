import React, { useEffect, useRef, useState } from "react";
import { FaExclamation, FaHeart } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ContactFormComponent from "../../components/contact-form-component/contact-form-component";
import Invoice from "../../models/data/invoice";
import LineItem from "../../models/data/line-item";
import { SubscriptionTierMapper } from "../../models/data/subscription-tier";
import UserSession from "../../models/data/user-session";
import UserSite from "../../models/data/user-site";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import PaymentService from "../../services/payment/payment-service";
import SiteService from "../../services/session/site-service";
import UserService from "../../services/session/user-service";
import ToastService from "../../services/ui/toast-service";
import "./order-confirmation-page.scss";
export default function OrderConfirmationPage() {
	const [allSites, setAllSites] = useState<UserSite[]>([]);
	const [newSite, setNewSite] = useState<UserSite>(null);
	const [orderInvoice, updateInvoice] = useState<Invoice>(null);
	const [loading, setLoading] = useState(true);
	const userSession = useRef<UserSession>(UserService.getCurrentSession());
	let { siteId } = useParams();

	useEffect(() => {
		SiteService.getUserSites((_: UserSite[]) => {
			setAllSites(_);
		});
	}, []);

	useEffect(() => {
		allSites.forEach((s: UserSite) => {
			if (s.id === siteId) {
				setNewSite({ ...s });
				SiteService.setCurrentSite({ ...s });

				PaymentService.GetLatestInvoice(s, {
					success(_: Invoice | null) {
						updateInvoice(_);
						setLoading(false);
						ToastService.OpenToast(
							"new_site_added",
							"Subscription Started",
							`Your subscription has started for ${s.name}`,
							FaHeart,
							new ToastConfig({
								autoHide: true,
								iconColour: "success",
							})
						);
					},
					error(err) {
						updateInvoice(null);
						ToastService.OpenToast(
							"error_fetching_invoice",
							"Error Fetching Invoice",
							"If this problem persists, please contact our support team.",
							FaExclamation,
							new ToastConfig({
								iconColour: "danger",
							})
						);
					},
				});
			}
		});
	}, [allSites, siteId]);

	const downloadInvoice = () => {
		if (orderInvoice === null) return;
		window.open(orderInvoice.invoice_pdf, "_blank");
	};

	const renderLineItems = () => {
		if (orderInvoice === undefined || orderInvoice.lines === undefined) return <></>;
		return (
			<div className="py-2">
				{orderInvoice.lines.map((li: LineItem, i: number) => {
					return (
						<div key={i}>
							<p className="d-flex justify-content-between align-items-center mb-0">
								<span className="h6 m-0">{li.description}</span>
								<strong className="m-0 ms-4 text-nowrap number">{PaymentService.RenderPrice(li.amount)}</strong>
							</p>
						</div>
					);
				})}
			</div>
		);
	};

	const renderDetails = () => {
		if (newSite === null || orderInvoice.lines === null || orderInvoice === null) return <></>;
		var d = new Date(newSite.renewalDate);
		return (
			<>
				<h3>Order Details</h3>
				<p className="mb-1">
					You purchased the <b>{SubscriptionTierMapper.FromTier(newSite.subscriptionTier)} PLAN</b>
				</p>
				<p className="mb-1">
					This subscription renews on{" "}
					<b className="number">{`${("0" + d.getDate()).slice(-2)}/${("0" + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`}</b>
				</p>

				<div className="mt-2 border-top pb-2"></div>
				<div>{renderLineItems()}</div>
				<div className="border-bottom pt-2 mb-2"></div>
				<div>
					<p className="text-end">
						Total Paid: <strong className="number">{PaymentService.RenderPrice(orderInvoice.amount)}</strong>
					</p>
				</div>
			</>
		);
	};

	return (
		<>
			{loading ? (
				<div className="text-center">
					<div className="m-auto spinner-border text-primary" role="status" style={{ width: "5rem", height: "5rem" }}>
						<span className="visually-hidden">Loading...</span>
					</div>
					<h4>Loading...</h4>
				</div>
			) : (
				<>
					{orderInvoice === null || newSite === null || orderInvoice === undefined || newSite === undefined ? (
						<>
							<div className="bg-white p-2 card">
								<div className="text-center mb-5">
									<h1 className="display-3">
										<strong>Oh no!</strong>
									</h1>
									<span className="d-block">Looks like something went wrong.</span>
									<span className="d-block">We're not sure what happened. Please contact us and we'll get this made right!</span>
								</div>
								<div>
									<ContactFormComponent />
								</div>
							</div>
						</>
					) : (
						<>
							{window.confetti
								? window.confetti.addConfetti({
										particleCount: 100,
										spread: 70,
										origin: { y: 0.6 },
								  })
								: null}
							<div className="bg-white p-2 card">
								<div className="text-center mb-5">
									<h1 className="display-3">
										<strong>A great big thank you!</strong>
									</h1>
									<span className="d-block">Welcome to MyDataPro! Everything is set up ready for you.</span>
									<span className="d-block">
										We've sent a confirmation email to <b>{userSession.current.email}</b>
									</span>
								</div>

								<div className="d-block d-md-flex justify-content-around align-items-start">
									<div style={{ maxWidth: "400px" }}>{renderDetails()}</div>
									<div className="mt-4 ms-2 mt-md-0" style={{ maxWidth: "400px" }}>
										<h3>Lets get started!</h3>
										<p>
											Head over to the{" "}
											<Link className="text-primary text-decoration-underline" to="/dashboard/configuration">
												forms
											</Link>{" "}
											tab to get started creating forms for your new site!
										</p>
										<div className="mt-3">
											<button className="btn btn-primary" onClick={() => downloadInvoice()}>
												Download Invoice
											</button>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}
