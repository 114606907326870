import React, { useState } from "react";
import { FaBug } from "react-icons/fa";
import { SubscriptionTierMapper } from "../../models/data/subscription-tier";
import UserSite from "../../models/data/user-site";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import AddSiteRequest from "../../models/requests/AddSiteRequest";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import PaymentService from "../../services/payment/payment-service";
import SiteService from "../../services/session/site-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import PricingBoard from "../pricing-board/pricing-board";
import SelectableDropdown from "../selectable-dropdown/selectable-dropdown";
import StatefullInput from "../statefull-input/statefull-input";
import StatefullLabel from "../statefull-label/statefull-label";
import UserService from "../../services/session/user-service";
import "./create-site-modal.scss";
import ConfirmationModal from "../confirmation-modal/confirmation-modal";

export default function CreateSiteModal({ modalId }: { modalId: string }) {
	const [newSite, setNewSite] = useState<AddSiteRequest>(new AddSiteRequest());
	const [appSumoCode, setAppSumoCode] = useState<string>("");
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	const CreateNewSite = (useAppSumo: boolean) => {
		var errors = [];
		if (newSite.siteName === null || newSite.siteName === "") {
			errors.push({ key: "siteName", value: "Site name can not be empty" });
		}

		if (newSite.description === null || newSite.description === "") {
			errors.push({ key: "siteDescription", value: "Site description can not be empty" });
		}

		if (newSite.tags === null || newSite.tags === "" || newSite.tags.split(",").length === 0) {
			errors.push({ key: "siteTags", value: "Tags must include at least 2 tags" });
		}

		if (useAppSumo && (appSumoCode === null || appSumoCode === "")) {
			errors.push({ key: "appSumoCode", value: "AppSumo code can not be empty" });
		}

		if (errors.length > 0) {
			errorState.emit({ error: "Invalid Input", fixes: errors });
			console.log("Errors require fixing");
			return;
		}

		SiteService.addUserSite(
			newSite,
			useAppSumo,
			appSumoCode,
			(_: UserSite) => {
				if (_ === null) {
					return;
				}
				if (useAppSumo) {
					ModalService.CloseModal(modalId);
					if (window.confetti)
						window.confetti.addConfetti({
							particleCount: 100,
							spread: 70,
							origin: { y: 0.6 },
						});
					ModalService.OpenModal(
						"create_confirmation",
						<ConfirmationModal
							modalId="create_confirmation"
							options={{
								header: "Site Creation Complete",
								body: `Congratulations! Your site has been created on our ${SubscriptionTierMapper.FromTier(
									_.subscriptionTier
								)} tier!`,
								confirmText: null,
								declineText: "Okay",
							}}
							callback={() => {}}
						/>
					);
				} else {
					PaymentService.GetCheckoutSessionForSite(_, {
						success(_) {
							ModalService.CloseModal(modalId);
							window.location.href = _;
						},
						error(err) {
							ToastService.OpenToast(
								"start_sub_fail",
								"Failed to get checkout session",
								"If this continues please contact support",
								FaBug,
								new ToastConfig({
									iconColour: "danger",
									headerColour: "danger",
									borderColour: "danger",
								})
							);
						},
					});
				}
			},
			(error: IErrorResp) => {
				errorState.emit(error);
			}
		);
	};

	return (
		<>
			<div className="bg-white p-2 rounded mb-2">
				<div>
					<h3 className="mb-3">Create new site.</h3>
					<p className="text-muted m-0">
						Create a site to capture and store data, you can not change your domain once it has been created.
					</p>
					<StatefullLabel stateId="global" stateChangeEvent={errorState} />
					<div className="mt-2">
						<div className="rounded d-block">
							<div className="flex-fill py-1 mb-2">
								<StatefullInput
									stateId={`siteName`}
									label={"The domain for your site (Don't include the subdomain)"}
									placeholder={"Domain name e.g: besthikingshoes.com"}
									defaultValue={newSite.siteName}
									inputType={"text"}
									autocompleteType={"off"}
									onChangeCallback={(val: string) => {
										newSite.siteName = val;
										setNewSite({ ...newSite });
									}}
									onPressCallback={(_: any) => {}}
									stateChangeEvent={errorState}
								/>
							</div>

							<div className="flex-fill py-1 mb-2">
								<StatefullInput
									stateId={`siteDescription`}
									label={"A small description of your website does."}
									placeholder={"Site description e.g: Online hiking shoe store"}
									defaultValue={newSite.description}
									inputType={"text"}
									autocompleteType={"off"}
									onChangeCallback={(val: string) => {
										newSite.description = val;
										setNewSite({ ...newSite });
									}}
									onPressCallback={(_: any) => {}}
									stateChangeEvent={errorState}
								/>
							</div>

							<div className="flex-fill py-1 mb-2">
								<StatefullInput
									stateId={`siteTags`}
									label={"Separate tags with a comma."}
									placeholder={"Site tags e.g: e-commerce, shoe store, hiking"}
									defaultValue={newSite.description}
									inputType={"text"}
									autocompleteType={"off"}
									onChangeCallback={(val: string) => {
										newSite.tags = val;
										setNewSite({ ...newSite });
									}}
									onPressCallback={(_: any) => {}}
									stateChangeEvent={errorState}
								/>
							</div>

							{UserService.getCurrentSession().appSumoUser ? (
								<>
									<div className="mt-4">
										<StatefullInput
											stateId={`appSumoCode`}
											label={"Enter your redemption code from AppSumo 🌮"}
											placeholder={"AppSumo Code"}
											defaultValue={appSumoCode}
											inputType={"text"}
											autocompleteType={"off"}
											onChangeCallback={(val: string) => {
												setAppSumoCode(val);
											}}
											onPressCallback={(_: any) => {}}
											stateChangeEvent={errorState}
										/>
									</div>

									<div className="py-1 d-flex align-items-center justify-content-end gap-2">
										<span className="flex-space d-block mt-2"></span>
										<button
											className="d-block btn btn-outline-danger"
											onClick={() => {
												ModalService.CloseModal(modalId);
											}}
										>
											Cancel
										</button>
										<button
											className="d-block btn btn-primary"
											onClick={() => {
												CreateNewSite(true);
											}}
										>
											Confirm
										</button>
									</div>
								</>
							) : (
								<>
									<div className="py-1 mt-4">
										<p className="fs-5 mb-1">Select your subscription tier:</p>
										<SelectableDropdown
											stateId={""}
											options={["FREE", "STANDARD", "PREMIUM", "PROFESSIONAL"]}
											selectedOption={SubscriptionTierMapper.FromTier(newSite.subscriptionTier)}
											onChange={(val: string) => {
												newSite.subscriptionTier = SubscriptionTierMapper.ToTier(val);
												setNewSite({ ...newSite });
											}}
										/>
									</div>

									<div className="py-1 d-flex align-items-center justify-content-end gap-2">
										<button
											className="d-block btn btn-outline-danger"
											onClick={() => {
												ModalService.CloseModal(modalId);
											}}
										>
											Cancel
										</button>
										<button
											className="d-block btn btn-primary"
											onClick={() => {
												CreateNewSite(false);
											}}
										>
											Start Subscription
										</button>
									</div>

									<div className="py-1">
										<PricingBoard showButtons={false} showTitle={false} activeTier={newSite.subscriptionTier} />
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
