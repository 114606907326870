import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import UserService from "../session/user-service";

export default function AuthenticationInterceptor(axios: AxiosInstance) {
	axios.interceptors.response.use(
		(response: AxiosResponse) => {
			return response;
		},
		(error: AxiosError) => {
			if (error.response.status === 401) {
				UserService.sessionExpiredEvent.emit();
			}
			return Promise.reject(error);
		}
	);
}
