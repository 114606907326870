import { HTTP_CONSTANTS } from "../../constants/http-constants";
import UserSite from "../../models/data/user-site";
import UserSiteForm from "../../models/data/user-site-form";
import { IPaginationHeaders } from "../../models/interfaces/IPaginationHeaders";
import ExportDataRequest from "../../models/requests/ExportDataRequest";
import FetchDataResponse from "../../models/responses/FetchDataResponse";
import { IErrorResp } from "../../models/responses/IErrorResp";
import PaginationDataResponse from "../../models/responses/PaginationDataResponse";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";

function _getCurrentSiteData(
	site: UserSite,
	formAPIKey: string,
	pagination: IPaginationHeaders,
	callback: ResponseCallback<PaginationDataResponse<FetchDataResponse>>
): void {
	if (site == null) return;
	HttpService.get<PaginationDataResponse<FetchDataResponse>>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/data/${site.id}/${formAPIKey}/rows`,
		callback.success,
		callback.error,
		{
			pagination: JSON.stringify(pagination),
		}
	);
}

function _getFormMetrics(formAPIKey: string, callback: ResponseCallback<any>) {
	HttpService.get<any>(`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/reporting/${formAPIKey}/metrics`, callback.success, callback.error);
}

function _deleteSiteFormRow(site: UserSite, formAPIKey: string, rowId: number, callback: ResponseCallback<void>): void {
	if (site == null) return;
	HttpService.delete(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/data/${site.id}/${formAPIKey}/${rowId}/delete`,
		callback.success,
		callback.error
	);
}

function _exportData(formAPIKey: string, dataExportRequest: ExportDataRequest, callback: ResponseCallback<any>) {
	HttpService.post<ExportDataRequest, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/data/${formAPIKey}/export`,
		dataExportRequest,
		callback.success,
		callback.error,
		{
			"Response-Type": "blob",
			responseType: "blob",
		}
	);
}

function _deleteForm(
	site: UserSite,
	formAPIKey: string,
	callback: (forms: UserSiteForm[]) => void,
	errorCallback: (error: IErrorResp) => void
): void {
	HttpService.delete<UserSiteForm[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/${site.id}/${formAPIKey}/delete`,
		callback,
		(err: HttpError) => {
			errorCallback(err.error);
		}
	);
}

const FormService = {
	getCurrentSiteData: _getCurrentSiteData,
	exportData: _exportData,
	deleteForm: _deleteForm,
	deleteRow: _deleteSiteFormRow,
	getFormMetrics: _getFormMetrics,
};

export default FormService;
