export default class ExportDataRequest {
	startDate: Date;
	endDate: Date;
	sortBy: "ASC" | "DESC";

	public Build(startDate: Date, endDate: Date): ExportDataRequest {
		this.startDate = startDate;
		this.endDate = endDate;
		this.sortBy = "DESC";
		return this;
	}
}
