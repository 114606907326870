import React from "react";
import Button from "react-bootstrap/Button";
import NavBarComponent from "../../components/nav-bar-component/nav-bar-component";
import "./landing-page.scss";
import landing_demo from "../../assets/images/landing_demo.png";
import phone_mock from "../../assets/images/iPhone_Demo_new.png";
import FooterComponent from "../../components/footer-component/footer-component";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import {
	FaChevronLeft,
	FaChevronRight,
	FaQuoteLeft,
	FaCookie,
	FaPizzaSlice,
	FaHamburger,
	FaTwitter,
	FaBaby,
	FaRegClock,
	FaBirthdayCake,
	FaCloud,
	FaClipboardList,
	FaCheck,
	FaCode,
	FaBrain,
} from "react-icons/fa";
import PricingBoard from "../../components/pricing-board/pricing-board";
import { Link } from "react-router-dom";
import useScript from "../../misc/hooks/useScript";
import { HTTP_CONSTANTS } from "../../constants/http-constants";
import { environment } from "../../environment/environment";
import MetricChartComponent from "../../components/metric-chart-component/metric-chart-component";
import { Accordion, Nav } from "react-bootstrap";

export default function LandingPage() {
	useScript(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/v1/2bf78cfc-41cc-4d60-be1b-2d7f233c6a9c`
			.replace(HTTP_CONSTANTS.protocol, environment.protocol)
			.replace(HTTP_CONSTANTS.baseAddress, environment.baseAddress)
	);
	SwiperCore.use([Pagination, Navigation]);

	const testimonial_slider_settings = {
		spaceBetween: 50,
		slidesPerView: 1,
		loop: true,
		loopedSlides: 1,
		loopAdditionalSlides: 1,
		pagination: {
			el: ".testimonial_slider_pagination",
			clickable: true,
		},
		navigation: {
			prevEl: ".testimonial_slider_prev",
			nextEl: ".testimonial_slider_next",
		},
		breakpoints: {
			1400: {
				slidesPerView: 2,
				loopedSlides: 2,
				loopAdditionalSlides: 2,
			},
			1200: {
				slidesPerView: 2,
				loopedSlides: 2,
				loopAdditionalSlides: 2,
			},
			992: {
				slidesPerView: 2,
				loopedSlides: 2,
				loopAdditionalSlides: 2,
			},
			768: {
				slidesPerView: 2,
				loopedSlides: 2,
				loopAdditionalSlides: 2,
			},
			576: {
				slidesPerView: 1,
				loopedSlides: 1,
				loopAdditionalSlides: 1,
			},
		},
	};

	const mockMetric = () => {
		const now = Date.now();
		const hour = 3600000;
		const day = hour * 24;

		const _ = [];
		for (let i = now - day * 6; i <= now; i += day) {
			_.push({ timestamp: i, value: Math.round(Math.random() * 100) });
		}

		return _;
	};

	return (
		<div>
			<NavBarComponent />
			<div className="landing-page header-space">
				<div className="page-width px-3 py-4 m-auto">
					<div className="w-100 flex-wrap d-flex justify-content-between align-items-center my-0">
						<div className="w-33 lg-100 d-flex flex-column justify-content-between">
							<h2 className="display-5">
								<b>
									<span className="fw-bold">
										Web Forms, but <br /> made{" "}
									</span>
									<i className="text-primary">easy.</i>
								</b>
							</h2>
							<h5 className="text-muted lh-base mt-4">
								Built for more than just developers. MyDataPro has been designed to give you the control over how you collect data on
								the web, helping grow audiences, track metrics and innovate your digital business.
							</h5>

							<div className="mt-4">
								<div className="d-flex">
									<Link to="/auth/register" className="d-block mb-2">
										<button className="btn btn-primary btn-lg">Get Started</button>
									</Link>
									<Nav.Link href="/#faq" className="d-block mb-2 ms-2">
										<button className="btn btn-outline-secondary btn-lg">Learn More</button>
									</Nav.Link>
								</div>
								<p className="text-muted mb-0">Sign Up and get access to our free tier to get started!</p>
							</div>
						</div>
						<div className="w-66 lg-100 my-5">
							<div>
								<img alt="" className="w-100" src={landing_demo} />
							</div>
							{/* <div className="mt-5 mx-auto text-center">
								<a
									href="https://www.producthunt.com/posts/mydatapro?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-mydatapro"
									target="_blank"
								>
									<img
										src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=380142&theme=light"
										alt="MyDataPro - Web&#0032;Forms&#0044;&#0032;but&#0032;made&#0032;easy | Product Hunt"
										style={{ width: "250px", height: "54px;" }}
										width="250"
										height="54"
									/>
								</a>
							</div> */}
						</div>
					</div>
				</div>

				<div className="p-2 my-4 bg-white page-width">
					<div className="text-center">
						<h3>Check out our responsive metrics!</h3>
						<p className="text-muted">
							No matter where you're vieweing from, youll always get the best information about your forms performance.
						</p>
					</div>
					<div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
						<MetricChartComponent
							metric={mockMetric()}
							title={"Cookies"}
							description={"Cookies Baked"}
							label={"Cookies Baked"}
							icon={<FaCookie />}
						/>

						<MetricChartComponent
							metric={mockMetric()}
							title={"Pizzas"}
							description={"Pizzas Ordered"}
							label={"Pizzas Ordered"}
							icon={<FaPizzaSlice />}
						/>

						<MetricChartComponent
							metric={mockMetric()}
							title={"Burgers"}
							description={"Burgers Eaten"}
							label={"Burgers Eaten"}
							icon={<FaHamburger />}
						/>
					</div>
					<p className="text-center my-1 text-muted">These metrics may or may not be true</p>
				</div>

				<div className="bg-light text-dark">
					<div id="how" className="page-width my-5">
						<div className="w-50 md-100 px-3 m-auto my-4">
							<p className="h1 text-center">How does it work?</p>
							<p className="text-center text-muted">The 4 simple steps to get you started!</p>
						</div>

						<div className="container-fluid m-auto row">
							<div className="col-md-3 mt-2">
								<div className="card h-100 text-dark bg-light border-light position-relative">
									<div
										className="position-absolute d-flex align-items-center justify-content-end top-0 left-0 end-0 bottom-0"
										style={{ zIndex: 1 }}
									>
										<h1 className="display-1 number text-secondary opacity-05" style={{ fontSize: "12rem" }}>
											<b>1</b>
										</h1>
									</div>
									<div className="card-body" style={{ zIndex: 2 }}>
										<div className="card-title">
											<div className="rounded bg-white p-2 w-content">
												<FaCloud className="text-info h3 mb-0" />
											</div>
										</div>
										<div className="card-title h4">Add your site plan</div>
										<p className="card-text">
											Create your FREE account and register your sites domain with one of our subscription tiers.
										</p>
									</div>
								</div>
							</div>

							<div className="col-md-3 mt-2">
								<div className="card h-100 text-dark bg-light border-light position-relative">
									<div
										className="position-absolute d-flex align-items-center justify-content-end top-0 left-0 end-0 bottom-0"
										style={{ zIndex: 1 }}
									>
										<h1 className="display-1 number text-secondary opacity-05" style={{ fontSize: "12rem" }}>
											<b>2</b>
										</h1>
									</div>
									<div className="card-body" style={{ zIndex: 2 }}>
										<div className="card-title">
											<div className="rounded bg-white p-2 w-content">
												<FaClipboardList className="text-danger h3 mb-0" />
											</div>
										</div>
										<div className="card-title h4">Create your form</div>
										<p className="card-text">
											Customise your form with our integrated form builder, picking what inputs you want to collect data for.
										</p>
									</div>
								</div>
							</div>

							<div className="col-md-3 mt-2">
								<div className="card h-100 text-dark bg-light border-light position-relative">
									<div
										className="position-absolute d-flex align-items-center justify-content-end top-0 left-0 end-0 bottom-0"
										style={{ zIndex: 1 }}
									>
										<h1 className="display-1 number text-secondary opacity-05" style={{ fontSize: "12rem" }}>
											<b>3</b>
										</h1>
									</div>
									<div className="card-body" style={{ zIndex: 2 }}>
										<div className="card-title">
											<div className="rounded bg-white p-2 w-content">
												<FaCode className="text-primary h3 mb-0" />
											</div>
										</div>
										<div className="card-title h4">Copy your snippet</div>
										<p className="card-text">
											Now copy the integration snippet into your website on the page you want your form to appear.
										</p>
									</div>
								</div>
							</div>

							<div className="col-md-3 mt-2">
								<div className="card h-100 text-dark bg-light border-light position-relative">
									<div
										className="position-absolute d-flex align-items-center justify-content-end top-0 left-0 end-0 bottom-0"
										style={{ zIndex: 1 }}
									>
										<h1 className="display-1 number text-secondary opacity-05" style={{ fontSize: "12rem" }}>
											<b>4</b>
										</h1>
									</div>
									<div className="card-body" style={{ zIndex: 2 }}>
										<div className="card-title">
											<div className="rounded bg-white p-2 w-content">
												<FaCheck className="text-success h3 mb-0" />
											</div>
										</div>
										<div className="card-title h4">Done!</div>
										<p className="card-text">
											Your form will automatically appear in your web page to collect responses! Any updates to your form will
											update instantly on your website!
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="">
					<div className="page-width py-5">
						<div className="container-fluid w-75 md-100 m-auto d-md-flex justify-content-around align-items-center">
							<div className="w-50 md-100">
								<div className="img-cont my-5 w-75 md-50 mx-auto position-relative">
									<img alt="phone mockup" src={phone_mock} />
								</div>
							</div>

							<div className="w-50 p-2 md-100 m-auto text-center text-md-start">
								<p className="display-6">Data on the go.</p>
								<p className="mt-2">
									MyDataPro's intuitive interface and ability to be accessed anywhere, anytime on any device is the perfect solution
									for business owners and individuals alike.
								</p>
								<p className="mt-2">
									Our innovative software helps you collect data from your website visitors, with no coding required.
								</p>

								<div className="text-center">
									<Link to="/auth/register" className="d-block m-auto">
										<Button className="d-block mt-5 mt-md-2 ms-auto me-auto ms-md-0" variant="primary" size="lg">
											Get Started
										</Button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-light text-dark">
					<div id="features" className="page-width my-5">
						<div className="w-50 md-100 px-3 m-auto my-4">
							<p className="h1 text-center">Our Features</p>
							<p className="text-center text-muted">
								My Data Pro is a data capture and hosting platform for form entry, online surveys, newsletter subscriptions and custom
								applications.
							</p>
						</div>

						<div className="container-fluid m-auto row">
							<div className="col-md-3 mt-2">
								<div className="card h-100 py-3 text-black bg-warning hover-shadow border-warning">
									<div className="card-body">
										<div className="card-title">
											<FaBrain className="h3" />
										</div>
										<div className="card-title h4">AI Driven</div>
										<p className="card-text">
											Using the latest in AI technology we allow our customers to harness that power to create the most powerful
											and engaging web form campaigns.
										</p>
									</div>
								</div>
							</div>

							<div className="col-md-3 mt-2">
								<div className="card h-100 py-3 text-light bg-primary hover-shadow border-primary">
									<div className="card-body">
										<div className="card-title">
											<FaBaby className="h3" />
										</div>
										<div className="card-title h4">Easy Setup</div>
										<p className="card-text">
											No programming knowledge required, copy and paste our code snippet into your site and get started!
										</p>
									</div>
								</div>
							</div>

							<div className="col-md-3 mt-2">
								<div className="card h-100 py-3 text-light bg-danger hover-shadow border-danger">
									<div className="card-body">
										<div className="card-title">
											<FaRegClock className="h3" />
										</div>
										<div className="card-title h4">Always Available</div>
										<p className="card-text">When you're awake, we're awake. 365 days a year availability with cloud hosting.</p>
									</div>
								</div>
							</div>

							<div className="col-md-3 mt-2">
								<div className="card h-100 py-3 text-light bg-success hover-shadow border-success">
									<div className="card-body">
										<div className="card-title">
											<FaBirthdayCake className="h3" />
										</div>
										<div className="card-title h4">Seamless Integration</div>
										<p className="card-text">
											Integration is a piece of cake! Our 1 step integration guide makes it as easy as can be getting started.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary text-light">
					<div className="page-width py-5">
						<div className="container-fluid m-auto">
							<div className="w-100 px-3 m-auto mb-4">
								<p className="text-center my-auto">Get the latest news and offers.</p>
								<p className="h1 text-center mb-0">Subscribe to our Newsletter</p>
								<p className="text-center mb-0">
									A perfect excuse to try out our forms! We use our own service for our newsletter capture.
								</p>
							</div>

							<form id="mdp_FORM_2bf78cfc-41cc-4d60-be1b-2d7f233c6a9c">
								<div className="mb-3 w-50 md-100 m-auto">
									<input
										className="form-control form-control-lg"
										name={"email"}
										type={"email"}
										autoComplete={"email"}
										placeholder={"Your Email"}
									/>
								</div>

								<button className="btn btn-danger btn-lg d-block m-auto mdp_SUBMIT">Subscribe</button>
							</form>
						</div>
					</div>
				</div>

				<div className="py-5">
					<PricingBoard showTrialText={true} showButtons={true} />
				</div>

				<div className="py-5 bg-light" id="faq">
					<div className="page-width px-2">
						<h1 className="text-center mb-4">
							You have questions!
							<br /> We have answers.
						</h1>
						<Accordion defaultActiveKey="0" className="m-auto" style={{ maxWidth: "500px" }} alwaysOpen>
							<Accordion.Item eventKey="0">
								<Accordion.Header>What is MyDataPro?</Accordion.Header>
								<Accordion.Body>
									<p>
										MyDataPro is a service that allows anybody to create and integrate web forms into their websites with minimum
										effort!
									</p>
									<p>
										We take the stress away from hosting, development and maintenance by allowing simple setup and integration
										into any website.
									</p>
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="1">
								<Accordion.Header>What if I exceed my monthly submission cap?</Accordion.Header>
								<Accordion.Body>
									<p>
										Don't worry! Your data is stored securely until the first day of your monthly renewal where it will be
										deducted from that months allowance.
									</p>
									<p>Alternatively you can unlock the hidden submissions by upgrading to a larger plan.</p>
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="2">
								<Accordion.Header>Can I customise my form?</Accordion.Header>
								<Accordion.Body>
									<p>YES!</p>
									<p>All plans come with a custom form builder allowing multiple inputs per form for quick and easy setup.</p>
									<p>Although our higher tier plans allow full support for custom forms that dont need MyDataPro's form builder</p>
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="3">
								<Accordion.Header>Do I have to use the form builder?</Accordion.Header>
								<Accordion.Body>
									<p>To use custom HTML forms without the MDP form builder you need to use our Premium plan or above.</p>
									<p>This gives you access to build your own form and send us the request to manage!</p>
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="4">
								<Accordion.Header>How do I cancel my subscription?</Accordion.Header>
								<Accordion.Body>
									<p>No strings attached, you can cancel your subscription at any time.</p>
									<p>This does mean the data connected to your site will be lost and will need exporting before hand.</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>

						<div className="my-3">
							<a href="/auth/register" className="d-block mx-auto text-decoration-underline text-center fs-4">
								Did we mention you can sign up absolutely <span className="text-primary">FREE</span>?
							</a>
						</div>

						<p className="mt-3 mb-0 text-center">
							Still need to know more? Contact us on
							<a className="d-inline text-primary ms-2" href="https://twitter.com/mydatapro_uk" target={"_blank"} rel="noreferrer">
								<FaTwitter /> Twitter.
							</a>
						</p>
					</div>
				</div>

				<div className="text-light bg-primary" id="testimonials">
					<div className="page-width py-5">
						<div className="container">
							<div className="row m-auto align-items-stretch">
								<div className="col-md-4 px-4">
									<p className="h4 text-left mb-3">See what our users have to say.</p>
									<p className="text-left">
										With a great service comes even greater customers. We reached out to some of our users to see what they
										thought, see what some of them have to say.
									</p>
								</div>

								<div className="col-md-8 text-dark px-0 d-flex flex-column justify-content-between">
									<Swiper className="flex-fill w-100" {...testimonial_slider_settings}>
										<SwiperSlide className="container-fluid h-100 p-0">
											<div className="card w-100 h-100">
												<div className="card-body d-flex flex-column align-items-stretch">
													<h5 className="card-title">
														<FaQuoteLeft className="h3 text-secondary" />
													</h5>
													<p className="flex-fill card-text">MyDataPro saved us hundreds, on developer and hosting fees.</p>

													<div className="border-bottom mb-3"></div>

													<p className="card-text d-flex justify-content-between align-items-center">
														<span className="h5 m-0">Lance Jarvis</span>
														<small className="text-muted">Ecom brand owner</small>
													</p>
												</div>
											</div>
										</SwiperSlide>

										<SwiperSlide className="container-fluid h-100 p-0">
											<div className="card w-100 h-100">
												<div className="card-body d-flex flex-column align-items-stretch">
													<h5 className="card-title">
														<FaQuoteLeft className="h3 text-secondary" />
													</h5>
													<p className="flex-fill card-text">
														I've integrated MyDataPro for my clients and they couldn't be happier. It's just so easy!
													</p>

													<div className="border-bottom mb-3"></div>

													<p className="card-text d-flex justify-content-between align-items-center">
														<span className="h5 m-0">Luke Stonier</span>
														<small className="text-muted">Developer</small>
													</p>
												</div>
											</div>
										</SwiperSlide>

										<SwiperSlide className="container-fluid h-100 p-0">
											<div className="card w-100 h-100">
												<div className="card-body d-flex flex-column align-items-stretch">
													<h5 className="card-title">
														<FaQuoteLeft className="h3 text-secondary" />
													</h5>
													<p className="flex-fill card-text">
														We use MyDataPro on our website for users to sign up to our newsletter and it works perfectly.
													</p>

													<div className="border-bottom mb-3"></div>

													<p className="card-text d-flex justify-content-between align-items-center">
														<span className="h5 m-0">Ericka Lynda</span>
														<small className="text-muted">Business Owner</small>
													</p>
												</div>
											</div>
										</SwiperSlide>
									</Swiper>

									<div className="d-flex mt-2">
										<div className="d-flex align-items-center text-light">
											<div className="testimonial_slider_prev pe-2">
												<FaChevronLeft />
											</div>
											<div className="testimonial_slider_next ps-2">
												<FaChevronRight />
											</div>
										</div>
										<div className="flex-fill"></div>
										<div>
											<div className="testimonial_slider_pagination"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FooterComponent />
		</div>
	);
}
