import { FaExchangeAlt } from "react-icons/fa";
import { HTTP_CONSTANTS } from "../../constants/http-constants";
import { SiteDataColumn } from "../../models/data/site-data-column";
import UserSite from "../../models/data/user-site";
import UserSiteForm from "../../models/data/user-site-form";
import { ToastConfig } from "../../models/interfaces/IMDPToast";
import AddSiteRequest from "../../models/requests/AddSiteRequest";
import UpdateColumnsRequest from "../../models/requests/UpdateColumnsRequest";
import GetSitesResponse from "../../models/responses/GetSitesResponse";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../event/event-emitter";
import HttpService, { HttpError } from "../http/http-service";
import ToastService from "../ui/toast-service";
import UpgradeSiteRequest from "../../models/requests/UpgradeSiteRequest";

let currentSite: UserSite = null;
const _siteUpdatedEvent = new EventEmitter<UserSite>();
const _siteListUpdateEvent = new EventEmitter<void>();

function _addSiteForUser(
	addRequest: AddSiteRequest,
	useAppSumo: boolean,
	appSumoCode: string,
	callback: (site: UserSite) => void,
	errorCallback: (error: IErrorResp) => void
): void {
	if (useAppSumo) addRequest = { ...addRequest, useAppSumo: useAppSumo, appSumoCode: appSumoCode };
	HttpService.post<AddSiteRequest, UserSite>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/add`,
		addRequest,
		(_: UserSite) => {
			if (currentSite == null) _setCurrentSite(_);
			_siteListUpdateEvent.emit();
			callback(_);
		},
		(err: HttpError) => {
			errorCallback(err.error);
		}
	);
}

function _upgradeSiteForUser(
	site: UserSite,
	appSumoCode: string,
	callback: (site: UserSite) => void,
	errorCallback: (error: IErrorResp) => void
): void {
	const upgradeRequest = {
		siteId: site.id,
		siteName: site.name,
		appSumoCode: appSumoCode,
	} as UpgradeSiteRequest;

	HttpService.post<UpgradeSiteRequest, UserSite>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/appsumo/upgrade`,
		upgradeRequest,
		(_: UserSite) => {
			if (currentSite == null) _setCurrentSite(_);
			_siteListUpdateEvent.emit();
			callback(_);
		},
		(err: HttpError) => {
			errorCallback(err.error);
		}
	);
}

function _getSitesForUser(callback: (sites: UserSite[]) => void): void {
	HttpService.get<GetSitesResponse>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites`,
		(_: GetSitesResponse) => {
			if (_ === null || _ === undefined || _.sites.length === 0) {
				callback(null);
				_dispose();
				return;
			}
			setTimeout(() => {
				if (currentSite == null) _setCurrentSite(_.sites[0]);
				if (_.sites.find((s) => s.id === currentSite.id) === undefined) _setCurrentSite(_.sites[0]);
				var siteToUpdate = _.sites.find((s) => s.id === currentSite.id);
				if (siteToUpdate !== undefined) _setCurrentSite(siteToUpdate); // updates current site details
				callback(_.sites);
			}, 100);
		},
		() => {
			callback(null);
		}
	);
}

function _updateSite(siteToUpdate: UserSite, callback: (site: UserSite) => void, errorCallback: (error: IErrorResp) => void): void {
	HttpService.patch<UserSite, UserSite>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/${siteToUpdate.id}`,
		siteToUpdate,
		(_: UserSite) => {
			_updateLocalSite(_);
			callback(_);
		},
		(err: HttpError) => {
			errorCallback(err.error);
		}
	);
}

function _removeSite(siteId: string): void {
	if (currentSite && currentSite.id === siteId) _setCurrentSite(null);
	_siteListUpdateEvent.emit();
}

function _getFormsForSite(callback: (forms: UserSiteForm[]) => void): void {
	var currentSite = _getCurrentSite();
	if (currentSite == null) return;
	HttpService.get<UserSiteForm[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/${currentSite.id}/forms`,
		(_: UserSiteForm[]) => {
			if (_ == null) {
				return;
			}
			callback(_);
		},
		() => {
			callback(null);
		}
	);
}

function _addFormForSite(callback: (forms: UserSiteForm[]) => void, errorCallback: (error: IErrorResp) => void): void {
	var currentSite = _getCurrentSite();
	if (currentSite == null) return;
	HttpService.post<any, UserSiteForm[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/${currentSite.id}/forms`,
		{},
		(_: UserSiteForm[]) => {
			callback(_);
		},
		(err: HttpError) => {
			errorCallback(err.error);
		}
	);
}

function _updateSiteForm(formToUpdate: UserSiteForm, callback: (forms: UserSiteForm) => void, errorCallback: (error: IErrorResp) => void): void {
	var currentSite = _getCurrentSite();
	if (currentSite == null) return;
	HttpService.patch<UserSiteForm, UserSiteForm>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/${currentSite.id}/${formToUpdate.apiKey}/forms`,
		formToUpdate,
		(_: UserSiteForm) => {
			callback(_);
		},
		(err: HttpError) => {
			errorCallback(err.error);
		}
	);
}

function _getSiteColumns(formAPIKey: string, callback: (columns: SiteDataColumn[]) => void): void {
	HttpService.get<SiteDataColumn[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/${_getCurrentSite().id}/${formAPIKey}/columns`,
		(_: SiteDataColumn[]) => {
			if (_ == null) return null;
			callback(_);
		},
		() => {}
	);
}

function _updateSiteColumns(
	formAPIKey: string,
	request: UpdateColumnsRequest,
	callback: (columns: SiteDataColumn[]) => void,
	errorCallback: () => void
): void {
	HttpService.post<UpdateColumnsRequest, SiteDataColumn[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/sites/${_getCurrentSite().id}/${formAPIKey}/columns`,
		request,
		(_: SiteDataColumn[]) => {
			if (_ == null) return;
			callback(_);
		},
		() => {
			errorCallback();
		}
	);
}

function _setCurrentSite(site: UserSite): void {
	if (site === null || site === undefined) {
		localStorage.removeItem("_activeSite");
		currentSite = null;
		_siteUpdatedEvent.emit(site);
		return;
	}

	const sameSite = currentSite !== null && currentSite.id === site.id;
	currentSite = { ...site };
	localStorage.setItem("_activeSite", JSON.stringify(currentSite));
	_siteUpdatedEvent.emit(site);

	if (!sameSite)
		ToastService.OpenToast(
			"site_switched_toast",
			"Site Changed",
			`You are now viewing ${site.name}`,
			FaExchangeAlt,
			new ToastConfig({
				autoHide: true,
				iconColour: "success",
			})
		);
}

function _updateLocalSite(site: UserSite): void {
	if (currentSite === null || currentSite.id === site.id) _setCurrentSite(site);
}

function _getCurrentSite(): UserSite {
	if (currentSite == null) currentSite = JSON.parse(localStorage.getItem("_activeSite"));
	return currentSite;
}

function _dispose(): void {
	_setCurrentSite(null);
	localStorage.removeItem("_activeSite");
	_siteUpdatedEvent.emit(null);
}

const SiteService = {
	siteUpdatedEvent: _siteUpdatedEvent,
	siteListUpdateEvent: _siteListUpdateEvent,
	addUserSite: _addSiteForUser,
	upgradeUserSite: _upgradeSiteForUser,
	getUserSites: _getSitesForUser,
	updateSite: _updateSite,
	removeSite: _removeSite,
	getSiteForms: _getFormsForSite,
	addSiteForm: _addFormForSite,
	updateSiteForm: _updateSiteForm,
	setCurrentSite: _setCurrentSite,
	getCurrentSite: _getCurrentSite,
	getColumns: _getSiteColumns,
	updateColumns: _updateSiteColumns,
	Dispose: _dispose,
};

export default SiteService;
