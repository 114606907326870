import React from "react";
import ModalService from "../../services/ui/modal-service";
import "./confirmation-modal.scss";

export class ConfirmationOptions {
	constructor(public header: string, public body: string, public confirmText: string, public declineText: string) {}
}

export default function ConfirmationModal({
	modalId,
	options,
	callback,
}: {
	modalId: string;
	options: ConfirmationOptions;
	callback: (_: boolean) => void;
}) {
	return (
		<div className="bg-white p-3 rounded mb-2">
			<div className="mb-3">
				<h4 className="mb-0">{options.header}</h4>
			</div>
			<div>
				<p>{options.body}</p>
			</div>
			<div className="d-flex justify-content-end align-items-center">
				{options.declineText !== null && options.declineText !== "" ? (
					<button
						className="btn btn-outline-primary me-2"
						onClick={() => {
							callback(false);
							ModalService.CloseModal(modalId);
						}}
					>
						{options.declineText}
					</button>
				) : (
					<></>
				)}
				{options.confirmText !== null && options.confirmText !== "" ? (
					<button
						className="btn btn-danger"
						onClick={() => {
							callback(true);
							ModalService.CloseModal(modalId);
						}}
					>
						{options.confirmText}
					</button>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}
