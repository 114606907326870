import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landing-page/landing-page";
import DashboardPage from "./pages/dashboard-page/dashboard-page";
import Theme from "./misc/theme/theme";
import DashboardLandingPage from "./pages/dashboard-landing-page/dashboard-landing-page";
import LoginPage from "./pages/login-page/login-page";
import NotFoundPage from "./pages/not-found-page/not-found-page";
import RegisterPage from "./pages/register-page/register-page";
import RecoveryPage from "./pages/recovery-page/recovery-page";
import SiteConfigurationPage from "./pages/site-configuration-page/site-configuration-page";
import DashboardSitesPage from "./pages/dashboard-sites-page/dashboard-sites-page";
import SiteSettingsPage from "./pages/site-settings-page/site-settings-page";
import ModalService from "./services/ui/modal-service";
import OrderConfirmationPage from "./pages/order-confirmation-page/order-confirmation-page";
import ToastService from "./services/ui/toast-service";
import AccountSettingsPage from "./pages/account-settings-page/account-settings-page";
import IntegrationPage from "./pages/integration-page/integration-page";
import ForgotRecoveryPage from "./pages/forgot-recovery-page/forgot-recovery-page";
import IntegrationGuidePage from "./pages/integration-guide-page/integration-guide-page";
import TermsPage from "./pages/terms-page/terms-page";
import LoadingOverlay from "./components/loading-overlay/loading-overlay";
import JSConfetti from "js-confetti";
import AdminDashboardLandingPage from "./pages/admin-dashboard-landing-page/admin-dashboard-landing-page";
import SetupGuidePage from "./pages/setup-guide-page/setup-guide-page";

declare global {
	interface Window {
		confetti: any;
	}
}

function App() {
	window.confetti = new JSConfetti();

	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<Theme />}>
						{/* INDEX / LANDING ROUTE */}
						<Route index element={<LandingPage />} />
						<Route path="setup-guide" element={<SetupGuidePage />} />
						<Route path="terms" element={<TermsPage />} />

						{/* AUTH */}
						<Route path="auth">
							<Route index element={<NotFoundPage />} />
							<Route path="login" element={<LoginPage />} />
							<Route path="register" element={<RegisterPage />} />
							<Route path="recovery" element={<RecoveryPage />} />
							<Route path="forgot-recovery" element={<ForgotRecoveryPage />} />

							{/* REFERER SIGNUPS */}
							<Route path="referer/:refererName">
								<Route path="register" element={<RegisterPage useReferer={true} />} />
							</Route>
						</Route>

						{/* DASHBOARD ROUTES */}
						<Route path="dashboard" element={<DashboardPage />}>
							<Route index element={<DashboardLandingPage />} />
							<Route path=":formId" element={<DashboardLandingPage />} />
							<Route path="configuration" element={<SiteConfigurationPage />} />
							<Route path="settings" element={<SiteSettingsPage />} />
							<Route path="sites" element={<DashboardSitesPage />} />
							<Route path="integration" element={<IntegrationGuidePage />} />

							{/* PAYMENT EXTRAS */}
							<Route path="cancel/:siteId" element={<SiteSettingsPage />} />
							<Route path="confirmation/:siteId" element={<OrderConfirmationPage />} />

							<Route path="account">
								<Route index element={<AccountSettingsPage />} />
							</Route>
						</Route>

						{/*	THIRD PARTY ROUTES */}
						<Route path="integration/:APIKEY/:hostName" element={<IntegrationPage />} />

						{/* ADMIN ROUTES */}
						<Route path="admin" element={<DashboardPage />}>
							<Route index element={<AdminDashboardLandingPage />}></Route>
						</Route>

						{/* MISC ROUTES */}
						<Route path="*" element={<NotFoundPage />} />
					</Route>
				</Routes>

				<div>
					<ModalService.Component />
					<ToastService.Component />
					<LoadingOverlay.Component />
				</div>
			</Router>
		</>
	);
}

export default App;
