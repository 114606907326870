import React from "react";
import "./footer-component.scss";

import logo from "../../assets/images/mdp_logo_black.png";
import { FaRegCopyright } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function FooterComponent() {
	return (
		<div className="px-2 py-1 bg-light">
			<div className="page-width d-flex align-items-center justify-content-between">
				<div className="text-align-left">
					<Link to="/" className="d-block">
						<img src={logo} height="30" className="d-block" alt="mdp logo" />
					</Link>
				</div>
				<div className="flex-fill text-muted d-flex align-items-center justify-content-end ms-4">
					<FaRegCopyright className="me-1 small" />
					<span>{new Date().getFullYear()}, All rights reserved.</span>
				</div>
			</div>
		</div>
	);
}
